// ComponentRoutes.js
import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./HomePage/HomePage";
// import ServicePage from "./ServicesPage/ServicePage";
import TrainingPage from "./TrainingPage/TrainingPage";
import ContactUs from "./ContactUsPage/ContactUs";
import EmployeePage from "./EmployeePage/EmployeePage";
import CertificatePage from "./CertificatePage/CertificatePage";
import ProductPage from "./Productpage/Components/ProductPage";
import ProductDescription from "./Productpage/Components/ProductDescription";
import CourseContent from "./TrainingPage/CourseContent";
import Navbar from "./Navbar/Navbar";
import Blog from "./Blog/Blog";
import DetailsBlogPage from "./Blog/Components/DetailsBlogPage";
import ServicePage from "./ServicesPage/ServicePage";
import PrivacyPolicy from "./PolicyPage/PrivacyPolicy";
import RefundPolicy from "./PolicyPage/RefundPolicy";
// import HIS from "./Productpage/Components/HIS";
import { Medbot } from "./Productpage/Components/Medbot";
import { Ethnikart } from "./Productpage/Components/Ethnikart";
import { Chasi } from "./Productpage/Components/Chasi";
import { Mediclub } from "./Productpage/Components/Mediclub";
import { Unhu } from "./Productpage/Components/Unhu";
import AboutUs from "./AboutUsPage/AboutUs";
import Login from "./admin/Login";
// import AddCertificate from "./certificate/AddCertificate";
import Dashboard from "./admin/Dashboard";
import CertificateGenerator from "./CertificatePage/CertificateGenerator";
import Error from "./Error";
import LeaderProfile from "./AboutUsPage/Components/LeaderProfile";

const ComponentRoutes = () => {
  const [nav, setNav] = useState("home");
  const token = sessionStorage.getItem("token");

  return (
      <Router>
          <Navbar WhichTab={nav} />
          <Routes>
              <Route path="/" element={<HomePage setNav={setNav} />} />
              <Route path="/about" element={<AboutUs setNav={setNav} />} />
              <Route path="/about/:routingName" element={<LeaderProfile />} />
              <Route
                  path="/products"
                  element={<ProductPage setNav={setNav} />}
              />
              {/* <Route path="/products/his" element={<HIS setNav={setNav} />} /> */}
              <Route
                  path="/products/medbot"
                  element={<Medbot setNav={setNav} />}
              />
              <Route
                  path="/products/ethnicart"
                  element={<Ethnikart setNav={setNav} />}
              />
              <Route
                  path="/products/chasi"
                  element={<Chasi setNav={setNav} />}
              />
              <Route
                  path="/products/mediclub"
                  element={<Mediclub setNav={setNav} />}
              />
              <Route path="/products/unhu" element={<Unhu setNav={setNav} />} />
              <Route
                  path="/products/:prod"
                  element={<ProductDescription setNav={setNav} />}
              />
              <Route
                  path="/training"
                  element={<TrainingPage setNav={setNav} />}
              />
              <Route
                  path="/training/:tid"
                  element={<CourseContent setNav={setNav} />}
              />
              <Route
                  path="/contactus"
                  element={<ContactUs setNav={setNav} />}
              />
              <Route
                  path="/employee/:empID"
                  element={<EmployeePage setNav={setNav} />}
              />
              <Route
                  path="/certificate/:internID"
                  element={<CertificatePage setNav={setNav} />}
              />
              <Route path="/blog" element={<Blog setNav={setNav} />} />
              <Route
                  path="/blog/:blogid"
                  element={<DetailsBlogPage setNav={setNav} />}
              />
              <Route
                  path="/service"
                  element={<ServicePage setNav={setNav} />}
              />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/refundpolicy" element={<RefundPolicy />} />
              <Route path="/admin-login" element={<Login setNav={setNav} />} />

              <Route
                  path="/admin/dashboard"
                  element={
                      <Dashboard
                          setNav={setNav}
                      />
                  }
              />
              {token && (
                  <Route
                      path="/certificate-generator/:internID"
                      element={<CertificateGenerator setNav={setNav} />}
                  />
              )}

              {/* This is the last route */}
              <Route path="*" element={<Error />} />
          </Routes>
      </Router>
  );
};

export default ComponentRoutes;
