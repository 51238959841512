import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{paddingTop: "60px", minHeight: "100vh"}}>
      <div className="container align-items-center text-center text-sm-start text-white">
        <p>
          Effective Date: 04.07.2023
          <br />
          <br /> 
          Thank you for choosing UNHU, developed by Squbix Digital Pvt Limited.
          This Privacy Policy outlines how we collect, use, and protect your
          personal information when you use the UNHU mobile application ("App").
          By using the App, you agree to the terms and conditions of this
          Privacy Policy.
          <br />
          <br />
          <h5>Collection of Information</h5>
          <b>1.1 Registration Information: </b>
          When you create an account on UNHU, we may collect personal
          information such as your name, email address, gender, occupation, and
          address. This information helps us tailor the App's features and
          functionality to meet the needs of truck drivers, sanitization
          workers, health workers, and similar professions.
          <br />
          <b>1.2 Phone Number Verification: </b>
          In order to register and access the App, you will be required to
          provide a valid phone number. Upon entering a valid phone number, we
          will send a one-time password (OTP) to that mobile number for
          verification purposes.
          <br />
          <b>1.3 Aadhar Card Verification: </b>
          If you choose to register using your Aadhar Card, we may collect your
          Aadhar Number. To ensure the authenticity of the Aadhar Card, we will
          send a valid OTP to the mobile number linked with that Aadhar Number.
          <br />
          <b>1.4 User Content: </b>
          The UNHU App allows you to create and share various forms of content,
          including photos, videos, audios, and texts. Any information or
          content you voluntarily provide while using the App, including
          user-generated content, will be collected and stored.
          <br />
          <b>1.5 SOS Button: </b>
          The App includes an SOS button to seek police or medical help. In case
          of emergency, your location information may be collected and shared
          with the nearest police station or hospital, along with your contact
          details.
          <br />
          <br />
          <h5>Use of Information</h5>
          <b>2.1 Provide Services: </b>
          We use the collected information to provide, personalize, and improve
          the features and functionality of the UNHU App, specifically designed
          for truck drivers, sanitization workers, health workers, and similar
          professions. This includes enabling you to access the Adda screen,
          Batua screen, Profile, and Health features.
          <br />
          <b>2.2 Communication: </b>
          We may use your email address or phone number to communicate with you
          regarding your account, updates, and important information related to
          the App. This includes sending notifications, alerts, and relevant
          industry updates.
          <br />
          <b>2.3 Aggregated Data: </b>
          We may aggregate and anonymize the collected information to generate
          statistical or analytical insights specific to the needs of truck
          drivers, sanitization workers, health workers, and similar
          professions. These insights may be used for research, marketing, or
          other purposes.
          <br />
          <br />
          <h5>Data Security</h5>
          We take reasonable measures to protect your personal information from
          unauthorized access, loss, misuse, disclosure, or alteration. However,
          please be aware that no method of transmission over the internet or
          electronic storage is 100% secure, and we cannot guarantee the
          absolute security of your data.
          <br />
          <br />
          <h5>Sharing of Information</h5>
          <b>4.1 Third-Party Service Providers:</b>
          We may engage trusted third-party service providers to perform
          functions on our behalf, such as hosting, data analysis, and customer
          support. These service providers will have access to your personal
          information only to the extent necessary to perform their functions
          and are obligated to maintain the confidentiality of the information.
          <br />
          <br />
          <b>4.2 Collaboration with Organizations:</b>
          We may collaborate with organizations or authorities relevant to the
          trucking, sanitization, healthcare, or related industries to provide
          you with value-added services or improve the functionality of the App.
          In such cases, your personal information may be shared with these
          organizations solely for the purpose of collaboration.
          <br />
          <b>4.3 Legal Requirements:</b>
          We may disclose your personal information if required by law, court
          order, or governmental authority, or if we believe that such
          disclosure is necessary to enforce our rights, protect our property,
          or ensure the safety of our users or the public.
          <br />
          <br />
          <h5>Children's Privacy</h5>
          UNHU is not intended for use by individuals under the age of 13. We do
          not knowingly collect personal information from children. If you
          believe that we have inadvertently collected personal information from
          a child under the age of 13, please contact us immediately.
          <br />
          <br />
          <br />
          <h5>Changes to this Privacy Policy</h5>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and the "Effective Date" at the beginning of
          the policy will be updated accordingly. Your continued use of the App
          after the revised Privacy Policy is in effect constitutes your
          acceptance of the updated terms. <br />
          <br />
          <h5>Contact Us</h5>
          If you have any questions, concerns, or suggestions regarding this
          Privacy Policy or our privacy practices, please contact us at
          info@squbix.com.
          <br />
          <br />
          <br />
          By using the UNHU App, you acknowledge that you have read and
          understood this Privacy Policy and consent to the collection, use, and
          disclosure of your personal information as described herein.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
