import React, { useState, useRef, useEffect } from "react";
import AddCertificate from "../certificate/AddCertificate";
import { v4 as uuidv4 } from "uuid"; // Import uuidv4
import { QRCodeSVG } from "qrcode.react"; // Import QRCodeSVG
import { saveSvgAsPng } from "save-svg-as-png"; // Import saveSvgAsPng
import axios from "axios";
import { baseUrl, frontendUrl } from "../Url";
import { useNavigate } from "react-router";

const DataTable = () => {
    const [showModal, setShowModal] = useState(false);
    const [showData, setShowData] = useState(false);
    const [formData, setFormData] = useState({});
    const [isloading, setIsloading] = useState(false);
    const qrCodeRefs = useRef({}); // Ref to store QR code SVG elements
    const [certificateData, setCertificateData] = useState([]);

    const navigate = useNavigate();
    const handleShow = () => {
        setShowModal(true);
    };
    const handleClose = () => setShowModal(false);

    const getAllCertificates = async () => {
        try {
            const res = await axios.get(`${baseUrl}/getCertificate`, {
                headers: {
                    Authorization: `${sessionStorage.getItem("token")}`,
                },
            });
            setCertificateData(res?.data);
        } catch (error) {
            console.error("Error in fetching data", error);
        }
    };

    const reset = () => {
        setFormData({
            name: "",
            branch: "",
            college: "",
            designation: "",
            startDate: "",
            endDate: "",
            issueDate: "",
        });
    };

    const handleSubmit = async (e) => {
        try {
            if (
                !formData?.name ||
                !formData?.branch ||
                !formData?.college ||
                !formData?.designation ||
                !formData?.startDate ||
                !formData?.endDate ||
                !formData?.issueDate
            ) {
                alert("Please fill all the fields");
                return;
            }
            setIsloading(true);
            e.preventDefault();
            const id = uuidv4();
            const qrCodeValue = `${frontendUrl}/certificate/${id}`;
            const updatedFormData = { ...formData, id, qrCodeValue };
            setFormData(updatedFormData);
            const res = await axios.post(
                `${baseUrl}/createCertificate`,
                updatedFormData,
                {
                    headers: {
                        Authorization: `${sessionStorage.getItem("token")}`,
                    },
                }
            );
            console.log(res.data);
            if (res?.data) {
                getAllCertificates();
                reset();
                // setFormData({});
                setIsloading(false);
                setShowModal(false);
            }
        } catch (error) {
            console.error("Error in adding data", error);
            setIsloading(false);
        }
    };

    const handleDownloadQRCode = (qrCodeValue) => {
        setTimeout(() => {
            const svg = document.getElementById(`qrCode_${qrCodeValue}`);
            if (svg) {
                saveSvgAsPng(svg, `${qrCodeValue}.png`, { scale: 10 }); // Adjust scale factor as needed
            } else {
                console.error("SVG element not found");
            }
        }, 100);
    };

    const logout = () => {
        sessionStorage.removeItem("token");
        navigate("/");
    };
    useEffect(() => {
        if (sessionStorage.getItem("token")) {
            getAllCertificates();
            setShowData(true);
        }
    }, []);

    return (
        <div className="container-fluid" style={{ paddingTop: "6%" }}>
            {showData && (
                <div>
                    <div className="d-flex justify-content-end gap-4">
                        <button
                            className="btn btn-primary mb-3"
                            onClick={handleShow}
                        >
                            Add Data
                        </button>
                        <button className="btn btn-info mb-3" onClick={logout}>
                            Logout
                        </button>
                    </div>
                    <div
                        className={`modal fade bd-example-modal-lg ${
                            showModal ? "show d-block" : ""
                        }`}
                    >
                        <div className="modal-dialog modal-lg ">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Add Data</h5>
                                    <button
                                        type="button"
                                        className="close"
                                        onClick={handleClose}
                                    >
                                        <span>&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="modal-body">
                                        <AddCertificate
                                            formData={formData}
                                            setFormData={setFormData}
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleSubmit}
                                    >
                                        {isloading ? "Loading..." : "Add Data"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <table className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                {/* <th>ID</th> */}
                                <th>Name</th>
                                <th>Branch</th>
                                <th>College</th>
                                <th>Designation</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Issue Date</th>
                                <th>Verification Link</th>
                                <th>Certificate</th>
                                <th>QR Code</th>
                                <th>Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            {certificateData?.map((item) => (
                                <tr key={item.id}>
                                    {/* <td>{item.id}</td> */}
                                    <td>{item.name}</td>
                                    <td>{item.branch}</td>
                                    <td>{item.college}</td>
                                    <td>{item.designation}</td>
                                    <td>{item.startDate}</td>
                                    <td>{item.endDate}</td>
                                    <td>{item.issueDate}</td>
                                    <td>
                                        <a
                                            href={`/certificate/${item?.id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            View
                                        </a>
                                    </td>
                                    <td>
                                        <div
                                            style={{
                                                cursor: "pointer",
                                                color: "blue",
                                            }}
                                            //  href={`/certificate-generator/${item?.id}`}
                                            onClick={() =>
                                                navigate(
                                                    `/certificate-generator/${item?.id}`
                                                )
                                            }
                                            // target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Certificate
                                        </div>
                                    </td>
                                    <td>
                                        <QRCodeSVG
                                            id={`qrCode_${item.qrCodeValue}`}
                                            value={item.qrCodeValue}
                                            ref={(ref) =>
                                                (qrCodeRefs.current[item.id] =
                                                    ref)
                                            } // Store ref in qrCodeRefs
                                        />
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() =>
                                                handleDownloadQRCode(
                                                    item?.qrCodeValue
                                                )
                                            }
                                        >
                                            Download
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default DataTable;
