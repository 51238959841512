import React, { useState } from "react";
import { Link } from "react-router-dom";

const TrainingCard = ({ data, key }) => {
  const [hoverID, setHoverID] = useState(-1);
  return (
    <Link
      to={`/training/${data?.nav}`}
      className="training-card-link"
      key={key}
      onMouseEnter={() => setHoverID(data?.id)}
      onMouseLeave={() => setHoverID(-1)}
    >
      <div className="training-img-container">
        <img
          className={`training-card-img ${
            data.id === hoverID && "training-img-hovered"
          }`}
          src={data?.img}
          alt="img"
        />
      </div>
      <div
        className="training-card-name"
        style={data.id === hoverID ? { color: "#dbff00" } : {}}
      >
        {data?.courseName}
      </div>
      <div className="training-card-duration">{data?.duration}</div>
    </Link>
  );
};

export default TrainingCard;
