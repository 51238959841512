import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div
      style={{
        textAlign: "center",
        padding: "50px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          fontSize: "5vw",
          fontWeight: "bold",
          color: "rgba(255, 255, 255, 0.75)",
        }}
      >
        This page doesn't exist.
      </div>
      <Link
        to="/"
        style={{
          textDecoration: "none",
          color: "#dbff00",
          fontSize: "2vw",
        }}
      >
        Go back to home
      </Link>
    </div>
  );
};

export default Error;
