import React from "react";
import "./Calendar";

const CalendarDay = ({ changeCurrentDay, currentDay, holidays }) => {
  let firstDayOfMonth = new Date(
    currentDay.getFullYear(),
    currentDay.getMonth(),
    1
  );
  let weekdayOfFirstDay = firstDayOfMonth.getDay();
  let currentDays = [];

  for (let day = 0; day < 42; day++) {
    if (day === 0 && weekdayOfFirstDay === 0) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 7);
    } else if (day === 0) {
      firstDayOfMonth.setDate(
        firstDayOfMonth.getDate() + (day - weekdayOfFirstDay)
      );
    } else {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
    }

    const today = new Date();

    let calendarDay = {
      currentMonth: firstDayOfMonth.getMonth() === currentDay.getMonth(),
      date: new Date(firstDayOfMonth),
      month: firstDayOfMonth.getMonth(),
      number: firstDayOfMonth.getDate(),
      selected: firstDayOfMonth.toDateString() === currentDay.toDateString(),
      year: firstDayOfMonth.getFullYear(),
      isToday:
      firstDayOfMonth.toDateString() === today.toDateString(),
    };

    currentDays.push(calendarDay);
  }

  return (
    <div className="calendar-day-wrapper table-content">
      {currentDays.map((day, index) => {
        const isHoliday = holidays.find(
          (holiday) =>
            new Date(holiday.date).toDateString() === day.date.toDateString()
        );
        return (
          <div
            className={
              "calendar-day" +
              (day.currentMonth ? " current" : "") +
              (day.selected ? " selectedDate" : "") +
              (isHoliday ? " holiday" : "") +
              (day.isToday ? " today" : "")
            }
            onClick={() => changeCurrentDay(day)}
            key={index}
          >
            <p>{day.number}</p>
          </div>
        );
      })}
    </div>
  );
};

export default CalendarDay;
