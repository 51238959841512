import React, { useRef, useEffect, useState, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "../../Css/Products.css";
import { data } from "../../Assets/Data/ProductDetailsJSON";
import { useNavigate } from "react-router-dom";

//* Debounce utility function
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const later = () => {
      clearTimeout(timeout);
      func.apply(this, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export const ProductonLarge = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const containerRef = useRef(null);

  const handleIntersection = useCallback(
    debounce((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio >= 0.7) {
          setActive(parseInt(entry.target.dataset.index, 10));
        }
      });
    }, 200), // Debounce delay of 200ms
    []
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.7, // Trigger when 70% of the image is visible
    });

    const images = containerRef.current.querySelectorAll(".image-container");
    images.forEach((image) => observer.observe(image));

    return () => {
      images.forEach((image) => observer.unobserve(image));
    };
  }, [handleIntersection]);

  const handleClick = (id) => {
    navigate("/products/" + id);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "250px",
        position: "relative",
      }}
    >
      <div className="prod-large-container" ref={containerRef}>
        <div className="prod-large-img">
          {data.map((item, index) => (
            <div key={index} data-index={index} className="image-container">
              <img
                src={item.images[0]} // Assuming the first image is shown
                alt={item.name}
                className={`${item.type}`}
                style={{ willChange: "transform, opacity" }}
              />
            </div>
          ))}
        </div>
        <div className="prod-large-desc">
          <div className="prod-large-top">
            <AnimatePresence mode="wait">
              <motion.div
                className="prod-large-heading"
                key={data[active].name}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                style={{ willChange: "transform, opacity" }}
              >
                {data[active].name}
              </motion.div>
            </AnimatePresence>
          </div>

          <AnimatePresence mode="wait">
            <motion.div
              className="prod-large-info"
              key={active}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              style={{ willChange: "transform, opacity" }}
            >
              {data[active].desc}
            </motion.div>
          </AnimatePresence>

          <div
            className="prod-large-uses-btn"
            onClick={() => handleClick(data[active].link)}
            style={{ willChange: "transform, opacity" }}
          >
            View Project
          </div>
        </div>
      </div>
    </div>
  );
};
