import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

// components import
import CoursesCards from "./Assets/Data/CourseCards.json";
import Footer from "../Footer/Footer";
import TrainingCard from "./Components/TrainingCard";

//css import
import "./Css/TrainingPage.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

//Aos import
import Aos from "aos";
import "aos/dist/aos.css";

//import image
import amlan from "./Assets/IMG/amlan.png";
import jasmin from "./Assets/IMG/jasmin.png";
import yashpal from "./Assets/IMG/yashpal.png";

function TrainingPage({ setNav }) {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    setIsIframeLoaded(false);
    setNav("training");
    Aos.init({
      disable: window.innerWidth < 700,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <div className="training-main">
        <div className="training-main-card-container">
          {/* <div className="training-main-text-card">
            <div className="training-main-heading">
              We train you for tomorrow
            </div>
            <div className="training-main-content">
              We provide training to the students in the field of technology and
              management. We have a team of experts who have years of experience
              in their respective fields.
            </div>
          </div> */}
          {/* <div className="training-main-video">
            {!isIframeLoaded && <div className="skeleton-loader"></div>}
            <iframe
              title="training-video"
              src={`https://player.vimeo.com/video/943524748?badge=0&autopause=0&player_id=0&app_id=58479`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="training-video"
              onLoad={() => setIsIframeLoaded(true)}
              style={{ visibility: isIframeLoaded ? "visible" : "hidden" }}
            ></iframe>
          </div> */}
        </div>
        <div className="training-card-heading">
          Explore our Courses
        </div>
        <div className="training-main-content" style={{ padding: "0 80px 80px 80px" }}>
          We provide training to the students in the field of technology and
          management.
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="400"
          className="training-card-container"
        >
          {CoursesCards?.map((e, index) => (
            <TrainingCard data={e} key={index} />
          ))}
        </div>

        <div className="training-stats" ref={ref}>
          <div className="training-stat-left">
            <div className="training-stats-certification">
              <div className="training-bg-2"></div>
              {inView ? (
                <CountUp
                  className="training-stats-heading1"
                  end={87}
                  duration={2}
                />
              ) : (
                <div className="training-stats-heading1">0</div>
              )}
              <span className="training-stats-heading2">Certifications</span>
            </div>
            <div className="training-stats-hired">
              {inView ? (
                <CountUp
                  className="training-stats-heading1"
                  end={20}
                  duration={2}
                />
              ) : (
                <div className="training-stats-heading1">0</div>
              )}
              <span className="training-stats-heading2">Students Hired</span>
            </div>
          </div>
          <div className="training-stats-review">
            <Swiper
              cssMode={true}
              navigation={true}
              pagination={true}
              mousewheel={true}
              keyboard={true}
              loop
              modules={[Navigation, Pagination, Mousewheel, Keyboard]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="trainee-profile">
                  <img
                    src={jasmin}
                    alt="profile"
                    className="trainee-profile-pic"
                  />
                  <div className="trainee-profile-review">
                    This course allow me to learn by solving real-world problems
                    through personalized learning opportunities.
                  </div>
                  <div>
                    <div className="trainee-profile-name">
                      Aradhana Tripathy
                    </div>
                    <div className="trainee-profile-location">
                      B.Tech, Trident Institute of Technology Bhubaneswar
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="trainee-profile">
                  <img
                    src={amlan}
                    alt="profile"
                    className="trainee-profile-pic"
                  />
                  <div className="trainee-profile-review">
                    Courses are very good and the teachers are very helpful and
                    I am very happy with the courses.
                  </div>
                  <div>
                    <div className="trainee-profile-name">Jyoti Prakash</div>
                    <div className="trainee-profile-location">
                      M.Tech, Trident Institute of Technology Bhubaneswar
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="trainee-profile">
                  <img
                    src={yashpal}
                    alt="profile"
                    className="trainee-profile-pic"
                  />
                  <div className="trainee-profile-review">
                    Squbix courses are the best for learning new technologies.
                    Try it once.
                  </div>
                  <div>
                    <div className="trainee-profile-name">
                      Sushil Ranjan Champati
                    </div>
                    <div className="trainee-profile-location">
                      B.Tech, Trident Institute of Technology Bhubaneswar
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        <div className="training-certificate-card-container">
          <div
            data-aos="fade-up"
            data-aos-duration="800"
            className="training-certificate"
          >
            <img
              src="https://ik.imagekit.io/z2g6kc7ex/Squbix/SqubixCertificate.png?updatedAt=1710758799493"
              alt=""
              style={{ width: "100%", borderRadius: "30px", height: "100%" }}
            />
          </div>
          <div className="training-certificate-card">
            <div data-aos="fade-left" className="training-main-heading">
              Our Certification
            </div>
            <div data-aos="fade-left" className="training-main-content">
              We provide certification to the students after the completion of
              the course. The certificate is valid and is accepted by the
              companies. These certificates are very helpful for the students in
              getting a job.
            </div>
          </div>
        </div>

        <div className="training-bg-1"></div>
      </div>
      <Footer />
    </div>
  );
}
export default TrainingPage;
