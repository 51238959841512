import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const img = [
  "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/hisdesc.png?updatedAt=1713183604298",
  "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/medbotdesc.png?updatedAt=1713183603946", 
  "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/ethnicdesc.svg?updatedAt=1710313994521", 
  "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/chasidesc.png?updatedAt=1713183604982",
  "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/mediclubdesc.svg?updatedAt=1710320695036",
  "https://ik.imagekit.io/z2g6kc7ex/Squbix/Products/unhudesc.png?updatedAt=1713183604471"
];

const ProductDescription = ({setNav}) => {
  const { prod } = useParams();

  useEffect(()=>{
    setNav("products");
    // eslint-disable-next-line
  },[])
  
  return (
    <div style={{ background: "black" }}>
      <div
        style={{
          maxWidth: "1024px",
          paddingTop: "100px",
          marginInline: "auto",
        }}
      >
        <img src={img[prod]} alt="prodimg" style={{ width: "100%" }} />
      </div>
    </div>
  );
};

export default ProductDescription;
