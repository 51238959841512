import React, { useEffect, useRef, useState } from "react";
import HeroSection from "./Components/HeroSection";
import Dashboard from "./Components/Dashboard";
import "./Css/HomePage.css";
import Products from "./Components/ProductdSection/Products";
import Footer from "../Footer/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { Service } from "./Components/Service";
import { ProductonLarge } from "./Components/ProductdSection/ProductonLarge";
import teams from "./Assets/Images/home-contact/teams.png";
import { Link } from "react-router-dom";
import Clients from "./Components/Clients";

const HomePage = ({ setNav }) => {
  const heroRef = useRef(null);
  const serviceRef = useRef(null);
  const productRef = useRef(null);
  const dashboardRef = useRef(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    AOS.init({
      disable: window.innerWidth < 700,
    });
    setNav("home");

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Call the function once to get the initial window size
    handleResize();

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="scroll-container">
        <div className="scroll-area typewriter-area">
          <HeroSection heroRef={heroRef} serviceRef={serviceRef} />
        </div>
        <div className="scroll-area srevices-area">
          <Service serviceRef={serviceRef} />
          <div className="homeservice-bg1"></div>
        </div>
        <div className="language-container">
          <div className="prod-page-bg-2" style={{ background: "none" }}></div>
          <div className="prodHeading">Our Clients/Partners</div>
          <Clients />
        </div>
        <div className="scroll-area product-area" ref={productRef}>
          <div className="prodHeading">Discover our Work</div>
          <div className="home-prod-content">
            Each project is a testament to our commitment to creating designs
            that not only look stunning but also deliver results.
          </div>
          <div
            style={
              windowWidth > 770 ? { display: "block" } : { display: "none" }
            }
          >
            <ProductonLarge />
          </div>

          <div
            style={
              windowWidth > 770 ? { display: "none" } : { display: "block" }
            }
          >
            <Products />
          </div>
        </div>
        <div className="scroll-area stat-area">
          <div className="prodHeading">Infodeck</div>

          <Dashboard dashboardRef={dashboardRef} />
        </div>

        <div className="home-contact-area">
          {/* <div className="home-contact-left">
            <img src={teams} alt="teams" />
          </div> */}
          <div className="home-contact-right">
            <div className="client-connect-box">
              <div className="home-contact-heading">
                Want us to transform your idea into a success story ?
              </div>
              <div className="home-contact-text">
                We've helped many companies to reach their business goals. Let`s
                make your project the next big thing.
              </div>
            </div>
            <Link to="/contactus" className="home-contact-btn">
              Become a Client
            </Link>
          </div>
        </div>

        <div style={{ width: "100%", marginTop: "25px" }}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
