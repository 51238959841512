import React from "react";
import "./App.css";
import ComponentRoutes from "./ComponentRoutes";
import "./swiper.min.css"
function App() {
  return (
    <div className="App">
        <ComponentRoutes />
    </div>
  );
}

export default App;
