import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";
const Login = () => {
  const navigation = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const res = await axios.post(`${baseUrl}/login`, formData);
      if (res?.data?.success === true) {
        sessionStorage.setItem("token", res?.data?.token);
        navigation("/admin/dashboard");
      }
    } catch (error) {
      console.log("Error in Admin Login ", error);
    }
  };

  return (
    <div className="container d-flex align-items-center vh-100">
      <div className="row justify-content-center w-100 text-white">
        <div className="col-md-6">
          <h2 className="text-center text-white">Login</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-4">
              <label htmlFor="email">Email address:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="pwd">Password:</label>
              <input
                type="password"
                className="form-control"
                id="pwd"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
            </div>
            <div className="form-group form-check mt-3">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="rememberMe"
                  checked={formData.rememberMe}
                  onChange={handleChange}
                />{" "}
                Remember me
              </label>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button type="submit" className="btn btn-primary">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
