// EmployeePage.js
import React from 'react';
import { useParams } from 'react-router-dom';
import EmployeeData from './Assets/Data/EmployeeData.json';
import './Css/EmployeePage.css';

function EmployeePage() {
    const { empID } = useParams();
    const thisEmp = EmployeeData.find(emp => emp.empID === empID);

    if (!thisEmp) {
        return <div>Employee not found</div>;
    }

    return (
        <>
            <div className='EmployeePage'>
                <div key={thisEmp.id} className='EmpCard'>
                    <div>
                        <img className='empImage' style={{ width: thisEmp.imgWidth, height: thisEmp.imgHeight }} src={thisEmp.empImg} alt=''></img>
                    </div>
                    <div>
                        <div className='EmpName'>{thisEmp.empName}</div>
                        <div className='EmpRole'>{thisEmp.empRole}</div>
                        <a href={`mailto:${thisEmp.empMail}`}><div className='EmpMail'>{thisEmp.empMail}</div></a>
                        <a href={`tel:${thisEmp.empMob}`}><div className='EmpMob'>{thisEmp.empMob}</div></a>
                        <div className='Stats'>
                            <div className='empID'>{thisEmp.empID}</div>
                            <div style={thisEmp.empStatus === "Employed" ? { color: "#a3b18a" } : { color: "#f7a399" }} className='EmpStat'>{thisEmp.empStatus}</div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default EmployeePage;
