import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Language from "./Language";

import Aos from "aos";
import "aos/dist/aos.css";

import "./ProductPage.css";

import His from "../Assets/IMG/his.png";
import ethnikart from "../Assets/IMG/ethnikart.png";
import medibot from "../Assets/IMG/medibot.png";
import chasi from "../Assets/IMG/chasi.png";
import mediclub from "../Assets/IMG/mediclub.png";
import unhu from "../Assets/IMG/unhu.png";
import Footer from "../../Footer/Footer";
import teams from "../../HomePage/Assets/Images/home-contact/teams.png";

const Card = ({ setNav }) => {
  const [isVisible, setIsVisible] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setNav("products");
    Aos.init({
      disable: window.innerWidth < 700,
    });
    // eslint-disable-next-line
  }, []);

  const handleMouseOver = (index) => {
    setIsVisible(index);
  };

  const handleMouseOut = () => {
    setIsVisible(null);
  };

  const data = [
    // {
    //   img: His,
    //   name: "Hospital management system",
    //   sector: "Healthcare",
    //   device: "Web & Mobile",
    //   id: "his",
    // },
    {
      img: medibot,
      name: "Medbot",
      sector: "Healthcare",
      device: "Web & Mobile",
      id: "medbot",
    },
    {
      img: ethnikart,
      name: "Ethnicart",
      sector: "eCommerce",
      device: "Web & Mobile",
      id: "ethnicart",
    },
    {
      img: chasi,
      name: "Chasi",
      sector: "Agrotech",
      device: "Web & Mobile",
      id: "chasi",
    },
    {
      img: mediclub,
      name: "Mediclub",
      sector: "Healthcare",
      device: "Mobile",
      id: "mediclub",
    },
    {
      img: unhu,
      name: "Unhu",
      sector: "Social",
      device: "Mobile",
      id: "unhu",
    },
  ];
  const navigate = useNavigate();
  const buttonClick = (index) => {
    // navigate("/products/" + id);
    navigate("/products/" + data[index].id);
  };
  return (
    <div
      style={{
        background:
          "linear-gradient(to bottom, #000, rgba(0, 0, 0, 0.5) 10%, transparent 20%)",
        width: "100vw",
        overflowX: "hidden",
      }}
    >
      <div className="prod-page-herosection">
        <div className="prod-page-bg1"></div>
        <div className="black-tint-heading">
          <div className="prod-page-heading1">
            {" "}
            Bring your ideas to life
          </div>
          <div className="prod-page-heading2">
            {" "}
            From user-friendly websites to feature-rich mobile apps
          </div>
        </div>
      </div>
      <div
        className="product-page-container"
        style={{ position: "relative", zIndex: 1 }}
      >
        <div className="product-wrapper text-bg-dark bg-transparent">
          <div className="main-container">
            {data?.map((e, index) => (
              <div
                className="parent d-flex justify-content-center"
                key={index}
                data-aos="fade-up"
                data-aos-once="true"
                onClick={() => buttonClick(index)}
                onMouseOver={() => handleMouseOver(e.id)}
                onMouseOut={handleMouseOut}
              >
                <div className="image_container">
                  <div
                    className={`scaleimg ${e.id === isVisible && "hovered"}`}
                  >
                    <img
                      src={e?.img}
                      alt="img"
                      onLoad={() => setImageLoaded(true)}
                      style={{ display: imageLoaded ? "block" : "none" }}
                    />
                    <div
                      className="product-skleton"
                      style={{ display: imageLoaded ? "none" : "block" }}
                    ></div>
                  </div>
                  <div className="text_container p-3">
                    <div
                      className="text-name"
                      style={e.id === isVisible ? { color: "#dbff00" } : {}}
                    >
                      {e?.name}
                    </div>
                    <div className="text-chip">
                      <span>{e?.sector}</span>
                      <span>{e?.device}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="language-container">
        <div className="prod-page-bg-2"></div>
        <div data-aos="fade-up" className="prod-page-heading2">
          Leveraging cutting-edge tech stack to build the future.
        </div>
        <Language />
      </div>

      <div className="home-contact-area" style={{ marginBottom: "25px" }}>
        {/* <div className="home-contact-left">
          <img src={teams} alt="teams" />
        </div> */}
        <div className="home-contact-right">
          <div className="">
            <div className="home-contact-heading">
              Want us to transform your idea into a success story ?
            </div>
            <div className="home-contact-text">
              We've helped many companies to reach their business goals. Let`s
              make your project the next big thing.
            </div>
          </div>
          <Link to="/contactus" className="home-contact-btn">
            Become a Client
          </Link>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Card;
