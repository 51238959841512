import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Css/CertificatePage.css";
// import InternData from "./Assets/Data/CertificateDetails.json";
import verified from "./Assets/Icons/verified.png";
// import download from "./Assets/Icons/download.png";
import axios from "axios";
// import certificateFrame from "../ContactUsPage/Assets/Images/CertificateFrame.png";
import { baseUrl } from "../Url";
// import { QRCodeSVG } from "qrcode.react";
import { AllCertificateData } from "./CertificateData/CertificateData";

function CertificatePage() {
  const { internID } = useParams();
  const [certificateData, setCertificateData] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [loader, setLoader] = useState(true);
  const getCertificateDetails = async () => {
    if (AllCertificateData) {
      const thisIntern = AllCertificateData.find(
          (intern) =>
              intern?.id === internID || intern?.certificate_id === internID
      );
      if(thisIntern){
        setCertificateData(thisIntern);
        setLoader(false);
      } else {
        setErrorMsg("Certificate Not Valid !");
        setLoader(false);
      }
    }
    // try {
    //   setLoader(true);
    //   const res = await axios.get(`${baseUrl}/getCertificate/${internID}`);
    //   if (res?.data) {
    //     setCertificateData(res?.data);
    //     setLoader(false);
    //   } else if (!res?.data) {
    //     setErrorMsg("Certificate Not Valid !");
    //     setLoader(false);
    //   }
    // } catch (error) {
    //   console.error("Error in fetching data", error);
    //   setErrorMsg("Certificate Not Valid !");
    //   setLoader(false);
    // }
  };

  useEffect(() => {
    getCertificateDetails();
    // eslint-disable-next-line
  }, [internID]);

  // //   const thisIntern = InternData.find(
  // //     (intern) => intern.id === parseInt(internID)
  // //   );

  // if (errorMsg) {
  //   return (
  //   );
  // }
  // function formatDate(dateString) {
  //   const date = new Date(dateString);
  //   const day = date.getDate();
  //   let daySuffix;

  //   if (day > 3 && day < 21) daySuffix = "th";
  //   else daySuffix = ["th", "st", "nd", "rd"][day % 10];

  //   const monthNames = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ];

  //   const monthName = monthNames[date.getMonth()];
  //   const year = date.getFullYear();

  //   return {
  //     day: day,
  //     suffix: daySuffix,
  //     month: monthName,
  //     year: year,
  //   };
  // }

  return (
    <div>
      {loader ? (
        <div
          style={{
            paddingTop: "6%",
            height: "100vh",
          }}
          className="spinner-border"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div>
          {errorMsg ? (
            <>
              <div className="CertificatePage NotFound">
                Certificate Not Valid !
              </div>
            </>
          ) : (
            <>
              <div className="CertificatePage">
                <div className="EachCertificate">
                  <div className="CertificateDesc">
                    <strong>{certificateData?.name}</strong> , a student of{" "}
                    <strong>{certificateData?.branch}</strong> at{" "}
                    <strong>{certificateData?.college}</strong>, has
                    successfully completed the internship as a{" "}
                    <strong>{certificateData.designation}</strong> at Squbix
                    Digital from <strong>{certificateData?.startDate}</strong>{" "}
                    to <strong>{certificateData?.endDate}</strong>.
                  </div>
                  <div className="CertificationStat">
                    <div>Certification Date : {certificateData?.issueDate}</div>
                    <div className="Certified">
                      Certification Status :{" "}
                      <div className="VerifiedBox">
                        <img
                          className="verifiedsign"
                          src={verified}
                          alt=""
                        ></img>
                        verified
                      </div>
                    </div>
                    {
                      // eslint-disable-next-line
                      //   <div>
                      //     <a href={certificateData.certificateLink} download target="_blank">
                      //       <img className="verifiedsign" src={download} alt=""></img>
                      //     </a>
                      //   </div>
                    }
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
export default CertificatePage;
