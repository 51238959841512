import React, { useEffect } from "react";
import "../Css/Ethinicart.css";
import mainbg from "../Assets/IMG/ethnicart/main-bg.png";
import secondbg from "../Assets/IMG/ethnicart/second-pic.png";
import boldLeft from "../Assets/IMG/ethnicart/bold-left.png";
import boldRight from "../Assets/IMG/ethnicart/bold-right.png";
import sizechart from "../Assets/IMG/ethnicart/sizechartbg.png";
import advance from "../Assets/IMG/ethnicart/advance.png";
import brand from "../Assets/IMG/ethnicart/brand.png";
import brand1 from "../Assets/IMG/ethnicart/brand1.png";
import wishlist from "../Assets/IMG/ethnicart/wishlist.png";
import order from "../Assets/IMG/ethnicart/order.png";
import social from "../Assets/IMG/ethnicart/social.png";
import social1 from "../Assets/IMG/ethnicart/social1.png";
import reviewSection from "../Assets/IMG/ethnicart/review-section.png";
import review from "../Assets/IMG/ethnicart/review.png";

export const Ethnikart = ({ setNav }) => {
  useEffect(() => {
    setNav("products");
  });
  return (
    <div className="main-section-ethnicart">
      <div className="bg-black ">
        {/* main section */}
        <section>
          <div className="text-center text-white title ">ETHNICART</div>
          <div className="d-flex justify-content-center">
            <img src={mainbg} className="main-bg" alt="main-bg" />
          </div>
          <div
            className="main-title text-center "
            style={{ color: "#E9E9E9", paddingTop: "25px" }}
          >
            <span className="sub-title">Seamless</span> online shopping
          </div>
        </section>
        {/* Curated Collection */}
        <section className="second-section">
          <div
            className="curated-title text-center"
            style={{ marginTop: "60px" }}
          >
            Curated Collection
          </div>
          <div className="d-flex justify-content-center">
            <img src={secondbg} alt="mockup" className="second-img" />
          </div>
        </section>
        <div className="right-subtitle mobile">
          Cater to diverse style & preference
        </div>
        {/* Bold Section */}

        <section className="bold-section">
          <div className=" bold-container">
            <div className="dev">
              <img src={boldLeft} alt="bold-left" className="bold-left" />
            </div>
            <div className="mobile" style={{ paddingRight: "10%" }}>
              <img src={boldLeft} width={"100%"} alt="" />
            </div>
            <div>
              <div className="bold-right dev">
                <div className="right-title">BOLD SELECTION</div>
                <div className="right-subtitle">
                  Make fashion accessible to a wider audience.
                </div>
              </div>
              <div
                style={{ paddingLeft: "94px", paddingTop: "53px" }}
                className="dev"
              >
                <img src={boldRight} alt="..." width={"100%"} />
              </div>
              <div
                className="mobile"
                style={{ paddingLeft: "10%", paddingTop: "45px" }}
              >
                <img src={boldRight} alt="..." width={"100%"} />
              </div>
              <div className="bold-right mobile">
                <div className="right-title">BOLD SELECTION</div>
                <div className="right-subtitle">
                  Make fashion accessible to a wider audience.
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* size chart */}
        <section className="size-chart">
          <div className="d-flex gap-5 justify-content-center size-chart-container">
            <div>
              <img src={sizechart} alt="bg" className="size-chart-img" />
            </div>
            <div>
              <div className="size-title">
                Size chart and product description
              </div>
              <div className="size-subtitle dev">
                Detailed information to help you make informed purchasing
                decisions.
              </div>
            </div>
          </div>
          <div className="size-subtitle mobile">
            Detailed information to help you make informed purchasing decisions.
          </div>
        </section>

        {/* Advance Filter */}
        <section className="advance-filter">
          <div className="d-flex justify-content-center advance-container ">
            <div className="advance">
              <div className="advance-title">Advance Filtering and search</div>
              <div className="advance-subtitle dev">
                Refine product searches by brand, size, color, price, material,
                and other relevant criteria.
              </div>
            </div>
            <div>
              <img src={advance} alt="bg" className="advance-img" />
            </div>
            <div className="advance-subtitle mobile">
              Refine product searches by{" "}
              <span>brand, size, color, price, material</span>, and other
              relevant criteria.
            </div>
          </div>
        </section>

        {/* //Brands */}
        <section className="brand-container">
          <div className="d-flex gap-5 justify-content-center brand-section ">
            <div className="brand-title mobile">
              Extensive Selection of brands
            </div>
            <div>
              <img src={brand} alt="bg" className="brand-img dev" />
              <img src={brand1} alt="bg" className="brand-img mobile" />
            </div>
            <div>
              <div className="brand-title dev">
                Extensive Selection of brands
              </div>
              <div className="brand-subtitle">
                Over 1,400 national & international brands, including luxury
                options
              </div>
            </div>
          </div>
        </section>

        {/* wishlist */}
        <section>
          <div className="d-flex justify-content-between wishlist-section">
            <div className="wishlist-container">
              <div className="wishlist-title">Wishlist & saved items</div>
              <div className="wishlist-subtitle dev">
                Save products for later purchase, create shopping lists, and
                share them with friends.
              </div>
            </div>
            <div>
              <img src={wishlist} alt="..." className="wishlist-img" />
            </div>
            <div className="wishlist-subtitle mobile">
              <span> Save products</span> for later purchase, create shopping
              lists,<span> & share</span>
              them with friends
            </div>
          </div>
        </section>

        {/* order tracking */}
        <section className="order">
          <div>
            <div className="order-title text-center">
              Order tracking and delivery notification
            </div>
            <div className="odrder-subtitle text-center dev">
              Track your order progress in real-time and receive updates on
              delivery status.
            </div>
          </div>
          <div>
            <img width={"100%"} src={order} alt="orderpic" className="dev" />
            <img
              width={"100%"}
              src={order}
              alt="orderpic"
              className="mobile"
              style={{ scale: "1.5", margin: "50px 0" }}
            />
          </div>

          <div className="odrder-subtitle text-center mobile">
            Track your order progress in <span>real-time</span> and receive
            updates on
            <span>delivery status</span>.
          </div>
        </section>

        {/* social-media */}
        <section>
          <div className="d-flex justify-content-between social">
            <div className="d-flex align-items-center">
              <div
                className="social-left"
                // style={{
                //   paddingLeft: "124px",
                //   paddingRight: "124px",
                // }}
              >
                <div className="social-title">Social Media Integration</div>
                <div className="social-subtitle dev">
                  Share your favorite finds on social media platforms.
                </div>
              </div>
            </div>
            <div>
              <img src={social} alt="..." className="social-img dev" />
              <img src={social1} alt="..." className="social-img mobile" />
            </div>
            <div className="social-subtitle mobile">
              Share your favorite finds <span> on social media</span> platforms.
            </div>
          </div>
        </section>

        {/* Review */}
        <section>
          <div>
            <div className="review">
              <div className="review-title">Review and rating system</div>
              <div className="review-subtitle dev">
                Read reviews from other customers and leave your own feedback to
                help others.
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center review-img-container ">
            <img src={reviewSection} alt="" className="review-img dev" />
            <img src={review} alt="" className="review-img mobile" />
          </div>
          <div className="review-subtitle mobile">
            Read reviews from other customers and{" "}
            <span> leave your own feedback</span> to help others.
          </div>
        </section>
      </div>
    </div>
  );
};
