import React, { useEffect } from "react";
import farmers1 from "../Assets/IMG/Chasi/farmers.png";
import farmers2 from "../Assets/IMG/Chasi/farmers2.png";
import farmers3 from "../Assets/IMG/Chasi/farmers3.png";
import farmers4 from "../Assets/IMG/Chasi/farmers4.png";
import mockup from "../Assets/IMG/Chasi/chasiapp1.png";
import mockup1 from "../Assets/IMG/Chasi/chasiapp.png";
import mockup2 from "../Assets/IMG/Chasi/chasiapp2.png";
import mockup3 from "../Assets/IMG/Chasi/chasiapp3.png";
import mockup4 from "../Assets/IMG/Chasi/chasiapp4.png";
import mockup5 from "../Assets/IMG/Chasi/chasiapp5.png";
import mockup6 from "../Assets/IMG/Chasi/chasiapp6.png";
import banner from "../Assets/IMG/Chasi/banner1.png";
import comment from "../Assets/IMG/Chasi/comment1.png";
import payment from "../Assets/IMG/Chasi/payment.png";
import chat1 from "../Assets/IMG/Chasi/chat1.png";
import chat2 from "../Assets/IMG/Chasi/chat2.png";
import chat3 from "../Assets/IMG/Chasi/chat3.png";
import chat4 from "../Assets/IMG/Chasi/chat4.png";
// import chat1 from '../Assets/IMG/Chasi/chat1.png'
import farmer5 from "../Assets/IMG/Chasi/farmers5.png";

import farmerIcon from "../Assets/IMG/Chasi/farmer-icon.png";
import languageIcon from "../Assets/IMG/Chasi/language-icon.png";
import shieldIcon from "../Assets/IMG/Chasi/shield-icon.png";
import harvestIcon from "../Assets/IMG/Chasi/harvest-icon.png";
import profileright from "../Assets/IMG/Chasi/profileright.png";

import Aos from "aos";
import "aos/dist/aos.css";

import "../Css/Chasi.css";

export const Chasi = ({ setNav }) => {
    useEffect(() => {
        setNav("products");
        Aos.init({
            disable: window.innerWidth < 700,
        });
    });
    return (
        <div className="chasi-bg">
            <div className="mobile hero-title">
                <div className="heaidng1 text-white">Meet the</div>
                <div className="heaidng2 span-color-chasi">
                    farmer’s best friend
                </div>

                <div data-aos="fade-up" className="chasi-landing-mockup">
                    <img src={mockup} alt="mockup" />
                </div>
                <div className="mobile chasi-first-title">Chasi</div>
            </div>
            <div className="chasi-bg1 mobile">
                <img src={farmers1} alt="farmer1" className="" width={"100%"} />
                <div className="chasi-bg1-text">
                    <div className="chasi-bg1-heading">
                        Grow <span>smarter</span>, farm better!
                    </div>
                </div>
            </div>
            <div className="chasi-main">
                <div className="chasi-herosection dev">
                    <img src={farmers1} alt="farmer1" className="farmer-img" />
                    <div className="chasi-herosection-heading-container">
                        <div className="chasi-heading1">
                            Grow <span>smarter</span>, farm better!
                        </div>
                    </div>
                </div>
                <div className="dev">
                    <div className="chasi-heading2 ">
                        Meet the farmer's <span>best friend</span>
                    </div>
                    <div className="">
                        <div
                            data-aos="fade-up"
                            className="chasi-landing-mockup "
                        >
                            <img src={mockup} alt="mockup" />
                        </div>
                    </div>
                </div>
                <div className="mobile">
                    <div className=" text-center helper-title ">
                        How can it help farmers?
                    </div>
                    <div className="text-center weather-title">
                        Live Weather Updates
                    </div>
                    <div
                        className="text-center marketprice"
                        style={{ paddingTop: "17px" }}
                    >
                        <span className="text-white">Real-time</span> vegetable
                        market <span className="text-white">prices</span> across
                        regions
                    </div>
                </div>
                <div className="chasi-mockup mobile homescreen">
                    <img src={mockup1} alt="mockup1" />
                </div>
                <div className="dev">
                    <div className="chasi-mockup-text ">
                        <div className="chasi-mockup">
                            <img src={mockup1} alt="mockup1" />
                        </div>

                        <div className="chasi-text-container dev">
                            <div className="chasi-texts">
                                <div
                                    data-aos="fade-left"
                                    className="chasi-texts-heading"
                                >
                                    Live Weather Updates
                                </div>
                                <div
                                    data-aos="fade-left"
                                    className="chasi-texts-para"
                                >
                                    <span>Real-time</span> vegetable market
                                </div>
                                <div
                                    data-aos="fade-left"
                                    className="chasi-texts-para"
                                >
                                    <span>Prices</span> accross regions
                                </div>
                            </div>

                            <div className="chasi-texts">
                                <div
                                    data-aos="fade-left"
                                    className="chasi-texts-heading"
                                >
                                    Farmer News
                                </div>
                                <div
                                    data-aos="fade-left"
                                    className="chasi-texts-para"
                                >
                                    Lates <span>agriculture news,</span>
                                </div>
                                <div
                                    data-aos="fade-left"
                                    className="chasi-texts-para"
                                >
                                    market trends, farming
                                </div>
                                <div
                                    data-aos="fade-left"
                                    className="chasi-texts-para"
                                >
                                    techniques.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile">
                    <div className="text-center weather-title">Farmer News</div>
                    <div
                        className="text-center marketprice"
                        style={{ paddingTop: "17px" }}
                    >
                        Latest{" "}
                        <span className="text-white font-weight-bold">
                            agriculture news
                        </span>
                        , market trends, farming techniques.
                    </div>
                </div>

                <div className="mobile">
                    <div className="farmer1">
                        <img src={farmer5} width={"100%"} alt="" />
                        <div className="farmer1-container">
                            <div className="farmer1-title">
                                Get{" "}
                                <span className="farmer1-span">
                                    soil tested
                                </span>{" "}
                                to understand its composition and nutrient
                                deficiencies
                            </div>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-up" className="chasi-banner dev">
                    <img src={banner} alt="banner" />
                </div>
                <div className="mobile">
                    <div className="text-center weather-title">
                        Rental Machinery
                    </div>
                    <div
                        className="text-center marketprice"
                        style={{ paddingTop: "17px" }}
                    >
                        Rent agricultural machinery at competitive rates
                    </div>
                </div>
                <div className="mobile">
                    <div className="rental-img">
                        <img src={mockup2} alt="mockup2" width={"100%"} />
                        <div>
                            <div className="govt-title">Govt. Yojanas</div>
                            <div className="govt-subtitle">
                                Chasi helps you{" "}
                                <span className="text-white">stay update</span>{" "}
                                and facilitates{" "}
                                <span className="text-white">schemes</span>
                            </div>
                            <div className="sub-subtitle">
                                <span>right for you</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dev">
                    <div className="chasi-mockup-text ">
                        <div data-aos="fade-up" className="chasi-mockup ">
                            <img src={mockup2} alt="mockup2" />
                        </div>
                        <div className="chasi-text-container">
                            <div className="chasi-texts dev">
                                <div
                                    data-aos="fade-left"
                                    className="chasi-texts-heading"
                                >
                                    Rental Machinery
                                </div>
                                <div
                                    data-aos="fade-left"
                                    className="chasi-texts-para"
                                >
                                    Rent agricultural Machinery
                                </div>
                                <div
                                    data-aos="fade-left"
                                    className="chasi-texts-para"
                                >
                                    at competitive rates
                                </div>
                            </div>
                            <div className="chasi-texts dev">
                                <div
                                    data-aos="fade-left"
                                    className="chasi-texts-heading"
                                >
                                    Goverment Yojana
                                </div>
                                <div
                                    data-aos="fade-left"
                                    className="chasi-texts-para"
                                >
                                    <span>Chasi helps</span> you understand
                                </div>
                                <div
                                    data-aos="fade-left"
                                    className="chasi-texts-para"
                                >
                                    the eligibility criteria and
                                </div>
                                <div
                                    data-aos="fade-left"
                                    className="chasi-texts-para"
                                >
                                    application process
                                </div>
                            </div>
                            <div className="mobile">
                                <div className="text-center weather-title">
                                    Govt. Yojanas
                                </div>
                                <div
                                    className="text-center marketprice"
                                    style={{ paddingTop: "17px" }}
                                >
                                    Chasi helps you stay update and facilitates
                                    schemes right for you
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div data-aos="fade-up" className="chasi-farmer-comment">
                    <img src={farmers2} alt="farmer2" />
                    <div
                        data-aos="fade-up"
                        className="chasi-farmer-comment-text"
                    >
                        <img src={comment} alt="comment" />
                    </div>
                </div>
                <div className="mobile">
                    <div className="text-center scheme-title ">
                        Health Schemes
                    </div>
                    <div
                        className="text-center marketprice"
                        style={{ paddingTop: "17px" }}
                    >
                        Relevant government schemes & benefits
                    </div>
                </div>
                <div className="mobile">
                    <img
                        src={mockup4}
                        alt="mockup3"
                        width={"100%"}
                        className="health-schems"
                    />
                </div>
                <div className="dev">
                    <div className="chasi-mockup-text">
                        <div data-aos="fade-up" className="chasi-mockup">
                            <img src={mockup3} alt="mockup3" />
                        </div>
                        <div data-aos="fade-up" className="chasi-mockup">
                            <img src={mockup4} alt="mockup4" />
                        </div>
                        <div className="chasi-texts">
                            <div
                                data-aos="fade-left"
                                className="chasi-texts-heading"
                            >
                                Health Schemes
                            </div>
                            <div
                                data-aos="fade-left"
                                className="chasi-texts-para"
                            >
                                Relevant government schemes and benefits they
                                may be eligible for
                            </div>
                        </div>
                    </div>
                </div>

                <div data-aos="fade-up" className="chasi-farmer-comment">
                    <img src={farmers3} alt="farmer3" width={"100%"} />
                    <div
                        data-aos="fade-up"
                        className="chasi-farmer-comment-text chasi-farmer-payment"
                    >
                        <img src={payment} alt="payment" />
                    </div>
                </div>
                <div className="mobile">
                    <div className="text-center weather-title1">
                        Transition Tracking
                    </div>
                    <div
                        className="text-center marketprice"
                        style={{ paddingTop: "17px" }}
                    >
                        Track all farm expenses & secure in-app wallet for{" "}
                        <span className="text-white">
                            easy money management
                        </span>
                    </div>
                </div>
                <div className="mobile">
                    <img
                        src={mockup5}
                        alt="mockup5"
                        width={"100%"}
                        className="wallet-image"
                    />
                </div>
                <div className="mobile">
                    <img
                        src={profileright}
                        alt="mockup5"
                        width={"100%"}
                        className="profile-right-image"
                    />
                </div>
                <div className="dev">
                    <div className="chasi-mockup-text ">
                        <div data-aos="fade-up" className="chasi-mockup dev">
                            <img src={mockup5} alt="mockup5" />
                        </div>
                        <div className="chasi-texts dev">
                            <div
                                data-aos="fade-left"
                                className="chasi-texts-heading"
                            >
                                Transition Tracking
                            </div>
                            <div
                                data-aos="fade-left"
                                className="chasi-texts-para"
                            >
                                Track all your farm-related
                            </div>
                            <div
                                data-aos="fade-left"
                                className="chasi-texts-para"
                            >
                                expenses in one place.
                            </div>
                            <br />
                            <div
                                data-aos="fade-left"
                                className="chasi-texts-para"
                            >
                                Secure in-app wallet for easy
                            </div>
                            <div
                                data-aos="fade-left"
                                className="chasi-texts-para"
                            >
                                money management
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dev">
                    <div className="chasi-profile-container ">
                        <div
                            data-aos="fade-up"
                            className="chasi-profile-mockup"
                        >
                            <img src={mockup6} alt="mockup6" />
                        </div>
                        <div className="chasi-icon-text-container">
                            <div className="chasi-icon-text">
                                <div data-aos="fade-up" className="chasi-icon">
                                    <img src={farmerIcon} alt="farmerIcon" />
                                </div>
                                <div data-aos="fade-up" className="chasi-text">
                                    Personalization{" "}
                                </div>
                            </div>
                            <div className="chasi-icon-text">
                                <div data-aos="fade-up" className="chasi-icon">
                                    <img
                                        src={languageIcon}
                                        alt="languageIcon"
                                    />
                                </div>
                                <div data-aos="fade-up" className="chasi-text">
                                    Multilingual Support
                                </div>
                            </div>
                            <div className="chasi-icon-text">
                                <div data-aos="fade-up" className="chasi-icon">
                                    <img src={shieldIcon} alt="shieldIcon" />
                                </div>
                                <div data-aos="fade-up" className="chasi-text">
                                    Verified Users
                                </div>
                            </div>
                            <div className="chasi-icon-text">
                                <div data-aos="fade-up" className="chasi-icon">
                                    <img src={harvestIcon} alt="harvestIcon" />
                                </div>
                                <div data-aos="fade-up" className="chasi-text">
                                    Harvest Management
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mobile " style={{ paddingTop: "120px" }}>
                    <div className="chat-container">
                        <img src={chat1} width={"100%"} alt="" />
                    </div>
                    <div className="chat-container">
                        <img src={chat2} width={"100%"} alt="" />
                    </div>
                    <div className="chat-container">
                        <img src={chat3} width={"100%"} alt="" />
                    </div>
                    {/* <div className="chat-container">
                    <img src={chat4} width={"100%"} alt="" />
                  </div> */}
                </div>
                <div className="mobile weather-conatiner">
                    <div className="text-center weather1-title">
                        <span className="weather-title">Chasi </span>
                        makes
                    </div>
                    <div className="text-center weather2-title">
                        it all possible
                    </div>
                </div>
                <div
                    data-aos="fade-up"
                    className="chasi-farmer-comment"
                    // style={{ marginBottom: "150px" }}
                >
                    <img src={farmers4} alt="farmer4" />
                </div>
            </div>
        </div>
    );
};
