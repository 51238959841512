import React, { useEffect } from "react";
import { BsDot } from "react-icons/bs";

// images import
import unhupeople from "../Assets/IMG/unhu/people-unhu.png";
import heroimg from "../Assets/IMG/unhu/heroimage.png";
import mockup1 from "../Assets/IMG/unhu/mockup1.png";
import mockup2 from "../Assets/IMG/unhu/mockup2.png";
import mockup3 from "../Assets/IMG/unhu/mockup3.png";
import feature1 from "../Assets/IMG/unhu/feature1.png";
import feature2 from "../Assets/IMG/unhu/feature2.png";
import feature3 from "../Assets/IMG/unhu/feature3.png";
import people2 from "../Assets/IMG/unhu/people2.png";
import people3 from "../Assets/IMG/unhu/people3.png";
import family from "../Assets/IMG/unhu/family.png";
import people4 from "../Assets/IMG/unhu/prople4.png";
import boat from "../Assets/IMG/unhu/boat.png";
import police2 from "../Assets/IMG/unhu/police2.png";
import ambulance from "../Assets/IMG/unhu/ambulance.png";
// import feature4 from "../Assets/IMG/unhu/feature4.png";
import camel from "../Assets/IMG/unhu/Camel.png";
import post from "../Assets/IMG/unhu/post.png";
// import uparrow from "../Assets/IMG/unhu/uparr.png";
import sirenIcon from "../Assets/IMG/unhu/siren-icon.png";
import police from "../Assets/IMG/unhu/police.png";
import medicalbtn from "../Assets/IMG/unhu/medicalbtn.png";
import apollo from "../Assets/IMG/unhu/apollo.png";
import profile from "../Assets/IMG/unhu/profile.png";

import Aos from "aos";
import "aos/dist/aos.css";

// css import
import "../Css/Unhu.css";

export const Unhu = ({ setNav }) => {
    useEffect(() => {
        setNav("products");
        Aos.init({
            disable: window.innerWidth < 700,
        });
    });
    return (
        <>
            <div className="unhu-main-container">
                <div className="text-center unhu-title">UNHU</div>
                <div className="unhu-image-container">
                    <img
                        src={unhupeople}
                        alt=".."
                        className="unhu-people-image"
                    />
                </div>
                <div
                    className="unhu-heading-title"
                    style={{ color: "rgba(255, 255, 255, 0.6)" }}
                >
                    Give <span style={{ color: "#fff" }}>people</span> the power
                    to build{" "}
                    <span style={{ fontWeight: "700", color: "#fff" }}>
                        community
                    </span>
                </div>

                <div className="unhu-hero">
                    <img src={heroimg} alt="heroimg" />
                </div>
                <div>
                    <img src={people2} alt="..." width={"100%"} />
                </div>

                <div
                    data-aos="fade-right"
                    className="unhu-mockup-text-container"
                >
                    <div
                        className="unhu-heading"
                        style={{
                            color: "rgba(255, 255, 255, 0.6)",
                            width: "40%",
                        }}
                    >
                        Give <span style={{ color: "#fff" }}>people</span> the
                        power to build{" "}
                        <span style={{ fontWeight: "700", color: "#fff" }}>
                            community
                        </span>
                    </div>
                    <div data-aos="fade-up" className="unhu-mockup">
                        <img src={mockup1} alt="mockup1" />
                    </div>
                </div>

                <div className="unhu-people3">
                    <img src={people3} alt="..." width={"100%"} />
                </div>

                <div className="scroll-title-container">
                    <div className="text-center scroll-title">
                        Enables Socialising
                    </div>
                    <div className="scroll-title2">
                        <span className="scroll-span-title2">Comment</span> on
                        posts & spark engaging conversations.
                    </div>
                </div>

                <div className="unhu-mockup-text-container">
                    <div data-aos="fade-up" className="unhu-mockup">
                        <img src={mockup2} alt="mockup2" />
                    </div>
                    <div className="unhu-text unhu-text-dev">
                        <div data-aos="fade-left" className="unhu-heading">
                            Have a conversation
                        </div>
                        <div data-aos="fade-left" className="unhu-para">
                            <span>Comment</span> on posts and spark engaging
                            conversations.
                        </div>
                    </div>
                </div>

                <div className="unhu-family">
                    <img src={family} width={"100%"} alt="" />
                </div>
                <div className="scroll-title-container">
                    <div className="text-center scroll-title">
                        Interactive Features
                    </div>
                    <div className="scroll-title2">
                        <span className="scroll-span-title2">
                            Send message, photos & videos
                        </span>{" "}
                        to a friend or selected group of people.
                    </div>
                </div>

                <div className="unhu-mockup-text-container">
                    <div className="unhu-text">
                        <div data-aos="fade-right" className="unhu-heading">
                            Interactive Features
                        </div>
                        <div data-aos="fade-right" className="unhu-para dev">
                            Send message, photos and video to a friend or
                            selected group of people
                        </div>
                    </div>
                    <div data-aos="fade-up" className="unhu-mockup">
                        <img src={mockup3} alt="mockup3" />
                    </div>
                </div>

                <div className="mobile unhu-people4">
                    <img src={people4} alt="" width={"100%"} />
                </div>

                <div data-aos="fade-up" className="unhu-camel-post dev">
                    <img src={camel} alt="camel" />
                    <div data-aos="fade-up" className="unhu-post">
                        <img src={post} alt="post" />
                    </div>
                </div>

                <div className="unhu-banner dev">
                    <div className="b1">our</div>
                    <div className="b2">community</div>
                    <div className="b3">is evolving,</div>
                    <div className="b4">so are we</div>
                </div>

                <div className="unhu-feature-text">
                    <div data-aos="fade-right" className="unhu-para">
                        Create a detailed{" "}
                        <span className="span-color">profile</span> to showcase
                        your interests and{" "}
                        <span className="span-color">connect</span> with
                        like-minded people.
                    </div>
                    <div data-aos="fade-up" className="unhu-feature dev">
                        <img src={feature1} alt="feature1" />
                    </div>
                </div>
                <div className="mobile white-bar-container">
                    {/* <div className="bg-white white-bar">
                        <div className="white-bar-title">My Profile</div>
                        <div className="text-black white-bar-icon">{">"}</div>
                    </div>
                    <div className="bg-white white-bar">
                        <div className="white-bar-title">
                            Points Redemptions
                        </div>
                        <div className="text-black white-bar-icon">{">"}</div>
                    </div>
                    <div className="bg-white white-bar">
                        <div className="white-bar-title">
                            Transaction History
                        </div>
                        <div className="text-black white-bar-icon">{">"}</div>
                    </div> */}
                    <img src={profile} width={"100%"} alt="..." />
                </div>
                <div className="text-center mobile wallet-heading">
                    Built-in Wallet
                </div>

                <div className="unhu-feature-text">
                    <div data-aos="fade-up" className="unhu-feature">
                        <img src={feature2} alt="feature2" />
                    </div>
                    <div className="unhu-text">
                        <div data-aos="fade-left" className="unhu-heading dev">
                            Built-in Wallet
                        </div>
                        <div
                            data-aos="fade-left"
                            className="unhu-para  "
                        >
                            <span>
                                <BsDot size={30} />
                            </span>
                            Enjoy a convenient in-app wallet for
                            <span className="span-color"> secure </span>
                            transactions
                        </div>
                        <div
                            data-aos="fade-left"
                            className="unhu-para wallet-title dev"
                        >
                            <span>
                                <BsDot size={30} color="#FDD600" />
                            </span><span className="span-color">Earn points</span> for
                            your activity within the app.
                        </div>
                        <div
                            data-aos="fade-left"
                            className="unhu-para wallet-title mobile"
                        >
                            <span>
                                <BsDot size={30} color="#FDD600" />
                            </span><span className="span-color">Earn points</span> for
                            your
                        </div>
                        <div
                            data-aos="fade-left"
                            className="unhu-para  mobile"
                        >
                           activity within the app
                        </div>
                    </div>
                </div>
                <div className="dev">
                    <div className="unhu-features-texts-container">
                        <div data-aos="fade-up" className="unhu-feature-mockup">
                            <img src={feature3} alt="feature3" />
                        </div>
                        <div
                            data-aos="fade-right"
                            className="unhu-text-up unhu-para"
                        >
                            Use points to buy thoughtful <span>gifts</span> for
                            friends and family within the app.
                        </div>
                        <div
                            data-aos="fade-left"
                            className="unhu-text-bottom unhu-para"
                        >
                            <span>Redeem</span> your earned points for exciting{" "}
                            <span>discounts</span> and purchases.
                        </div>
                    </div>
                </div>
                <div className="reddem-conatiner mobile">
                    <div className="apolo-img">
                        <img src={apollo} alt="" width={"100%"} />
                    </div>
                    <div className="reddem-title">
                        <span className="span-color">Redeem</span> your earned
                        points for exciting discounts & purchases
                    </div>
                </div>
                <div className="reddem-conatiner mobile">
                    <div className="apolo-img">
                        <img src={boat} alt="" width={"100%"} />
                    </div>
                    <div className="reddem-title">
                        Use points to buy thoughtful{" "}
                        <span className="span-color">gifts</span> for friends &
                        family within the app
                    </div>
                </div>
                <div className="mobile">
                    <div className="text-center stay-title">Stay Informed</div>
                    <div className="police-image">
                        <img src={police2} alt="" width={"100%"} />
                        <div className="nearby-title">
                            Find nearby{" "}
                            <span className="span-color">
                                police stations and hospitals
                            </span>{" "}
                            with ease in case of emergencies
                        </div>
                        <div className="ambulance-image">
                            <img src={ambulance} alt="" width={"100%"} />
                        </div>
                    </div>
                </div>

                <div className="dev">
                    <div
                        data-aos="fade-up"
                        className="unhu-heading sos-title"
                        style={{ textAlign: "center" }}
                    >
                        Stay Informed
                    </div>
                    <div
                        data-aos="fade-up"
                        className="unhu-para"
                        style={{ textAlign: "center", marginInline: "auto" }}
                    >
                        Find nearby police stations and hospitals with ease in
                        case of emergencies.
                    </div>

                    <div data-aos="fade-up" className="unhu-siren">
                        <img src={sirenIcon} alt="sirenIcon" />
                        <div
                            className="unhu-heading"
                            style={{
                                textAlign: "center",
                                marginBottom: "20px",
                            }}
                        >
                            SOS
                        </div>
                        <div
                            className="unhu-para"
                            style={{
                                marginInline: "auto",
                                textAlign: "center",
                                marginBottom: "20px",
                            }}
                        >
                            How can we help you?
                        </div>
                        <div data-aos="fade-up" className="unhu-police">
                            <img
                                src={police}
                                alt="police"
                                className="police-img"
                            />
                        </div>
                        <div data-aos="fade-up" className="unhu-medical">
                            <img src={medicalbtn} alt="medicalbtn" />
                        </div>
                    </div>
                </div>

                <div data-aos="fade-up" className="unhu-heading2">
                    <span className="text-white app-name">UNHU</span> goes beyond just a social media platform. It fosters a
                    connected and informed community, empowering you to engage,
                    earn rewards, and stay informed .
                </div>
                <div className="all-in-one">All in one place.</div>
            </div>
        </>
    );
};
