
export const AllCertificateData = [
    {
        _id: {
            $oid: "646b4321b3058d919a93cd0a",
        },
        name: "Deepak Senapati",
        designation: "Backend/Cloud Developer",
        profile_pic: "",
        certificate_id: "5387b2e0ec61ca2b043c6fee7d4254fd41e411b1",
        issue_date: "2023-05-24",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-05-22T10:25:37.616Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-05-24",
        profilePic: "",
        qrCodeValue: null,
        startDate: null,
        id: "5387b2e0ec61ca2b043c6fee7d4254fd41e411b1",
    },
    {
        _id: {
            $oid: "63b69d70e5ccf1e5e2e963c2",
        },
        name: "Smruti Dash",
        designation: "React.js Developer",
        certificate_id: "15372a25defb9f7b8f7eeec2b2c6a21c25ac79d1",
        issue_date: "2023-01-05",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-01-05T09:50:40.527Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-01-05",
        profilePic: null,
        qrCodeValue: null,
        startDate: null,
        id: "15372a25defb9f7b8f7eeec2b2c6a21c25ac79d1",
    },
    {
        _id: {
            $oid: "63b69d89e5ccf1e5e2e963c5",
        },
        name: "Anubhab Patnaik",
        designation: "React.js Developer",
        certificate_id: "6d26eeb8eb37bd8d544bc50bedd4b3f3978d3dd0",
        issue_date: "2023-01-05",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-01-05T09:51:05.189Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-01-05",
        profilePic: null,
        qrCodeValue: null,
        startDate: null,
        id: "6d26eeb8eb37bd8d544bc50bedd4b3f3978d3dd0",
    },
    {
        _id: {
            $oid: "646b428db3058d919a93cd04",
        },
        name: "Amruta Ray",
        designation: "React.js Developer",
        profile_pic: "",
        certificate_id: "d5da67423e23169ab455605c464314817b36884e",
        issue_date: "2023-05-24",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-05-22T10:23:09.239Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-05-24",
        profilePic: "",
        qrCodeValue: null,
        startDate: null,
        id: "d5da67423e23169ab455605c464314817b36884e",
    },
    {
        _id: {
            $oid: "646b4351b3058d919a93cd0d",
        },
        name: "Rakesh Sahoo",
        designation: "Blockchain Developer",
        profile_pic: "",
        certificate_id: "b269b860987fc16b34324d6ad453c784f697294e",
        issue_date: "2023-05-24",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-05-22T10:26:25.434Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-05-24",
        profilePic: "",
        qrCodeValue: null,
        startDate: null,
        id: "b269b860987fc16b34324d6ad453c784f697294e",
    },
    {
        _id: {
            $oid: "63388849c484ead2bc190c31",
        },
        name: "Umang Tiwari",
        designation: "Backend Developer Intern",
        certificate_id: "AH3Y0bMDN5PRng621Gl7",
        issue_date: "7th September, 2021",
        issuer: "Squbix Digital Pte. Ltd.",
        type: "Internship Certificate",
        timestamp: {
            $date: "2022-10-01T18:34:49.897Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "7th September, 2021",
        profilePic: null,
        qrCodeValue: null,
        startDate: null,
        id: "AH3Y0bMDN5PRng621Gl7",
    },
    {
        _id: {
            $oid: "646b441cb3058d919a93cd19",
        },
        name: "Adyanshu Mishra",
        designation: "Rust/Blockchain Developer",
        profile_pic: "",
        certificate_id: "3dab96e242de504831f3c220a52f689d24698283",
        issue_date: "2023-05-24",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-05-22T10:29:48.931Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-05-24",
        profilePic: "",
        qrCodeValue: null,
        startDate: null,
        id: "3dab96e242de504831f3c220a52f689d24698283",
    },
    {
        _id: {
            $oid: "646b43aab3058d919a93cd13",
        },
        name: "Sanket Mohanty",
        designation: "React.js Developer",
        profile_pic: "",
        certificate_id: "b4cc9524d1a0dd644dcb1d13e941c39f7cf456d9",
        issue_date: "2023-05-24",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-05-22T10:27:54.538Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-05-24",
        profilePic: "",
        qrCodeValue: null,
        startDate: null,
        id: "b4cc9524d1a0dd644dcb1d13e941c39f7cf456d9",
    },
    {
        _id: {
            $oid: "633888e4c484ead2bc190c35",
        },
        name: "Alish Sulkiya",
        designation: "Frontend Developer Intern",
        certificate_id: "qTA9AzzV0pn0QgnzeNvE",
        issue_date: "1st October, 2021",
        issuer: "Squbix Digital Pte. Ltd.",
        type: "Appreciation Certificate",
        timestamp: {
            $date: "2022-10-01T18:37:24.909Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "1st October, 2021",
        profilePic: null,
        qrCodeValue: null,
        startDate: null,
        id: "qTA9AzzV0pn0QgnzeNvE",
    },
    {
        _id: {
            $oid: "63b69d27e5ccf1e5e2e963b9",
        },
        name: "Piyush Verma",
        designation: "React.js Developer",
        certificate_id: "a6751c341d7166c1e31090e47c56bea471854389",
        issue_date: "2023-01-05",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-01-05T09:49:27.048Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-01-05",
        profilePic: null,
        qrCodeValue: null,
        startDate: null,
        id: "a6751c341d7166c1e31090e47c56bea471854389",
    },
    {
        _id: {
            $oid: "63b69cdce5ccf1e5e2e963b3",
        },
        name: "Mahavir Dash",
        designation: "Rust and Blockchain developer",
        certificate_id: "a2e5d75bd41a3dec76a0046221aaca76616db5fa",
        issue_date: "2023-01-05",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-01-05T09:48:12.762Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-01-05",
        profilePic: null,
        qrCodeValue: null,
        startDate: null,
        id: "a2e5d75bd41a3dec76a0046221aaca76616db5fa",
    },
    {
        _id: {
            $oid: "63b69d0ce5ccf1e5e2e963b6",
        },
        name: "Disha Swarup",
        designation: "React.js Developer",
        certificate_id: "06e16222946c783746fbddfdc0d1301dc407a81c",
        issue_date: "2023-01-05",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-01-05T09:49:00.174Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-01-05",
        profilePic: null,
        qrCodeValue: null,
        startDate: null,
        id: "06e16222946c783746fbddfdc0d1301dc407a81c",
    },
    {
        _id: {
            $oid: "63b69dd3e5ccf1e5e2e963ce",
        },
        name: "Avinash Prasad",
        designation: "Marketing and Business Development",
        certificate_id: "1e6ffe429e38dc2f22c858f85bed031b48bb4a34",
        issue_date: "2023-01-05",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-01-05T09:52:19.985Z",
        },
        __v: 0,
        profile_pic:
            "https://firebasestorage.googleapis.com/v0/b/squbix-digital-service.appspot.com/o/interns_profile_pic%2FScreenshot%202022-11-18%20at%2012.23.57%20PM.png?alt=media&token=8cf6b638-b00c-4e51-a200-d03a388f4fd6",
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-01-05",
        profilePic:
            "https://firebasestorage.googleapis.com/v0/b/squbix-digital-service.appspot.com/o/interns_profile_pic%2FScreenshot%202022-11-18%20at%2012.23.57%20PM.png?alt=media&token=8cf6b638-b00c-4e51-a200-d03a388f4fd6",
        qrCodeValue: null,
        startDate: null,
        id: "1e6ffe429e38dc2f22c858f85bed031b48bb4a34",
    },
    {
        _id: {
            $oid: "646b4377b3058d919a93cd10",
        },
        name: "Swaugat Beura",
        designation: "Backend/Cloud Developer",
        profile_pic: "",
        certificate_id: "00f484e28b7223f07f01849c5929f4b48a72c5b5",
        issue_date: "2023-05-24",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-05-22T10:27:03.094Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-05-24",
        profilePic: "",
        qrCodeValue: null,
        startDate: null,
        id: "00f484e28b7223f07f01849c5929f4b48a72c5b5",
    },
    {
        _id: {
            $oid: "63b69c47e5ccf1e5e2e963ae",
        },
        name: "Sourav Mishra",
        designation: "Rust and Blockchain developer",
        certificate_id: "1b678ab65e745b6f991d8297e82300e2f56330c1",
        issue_date: "2023-01-05",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-01-05T09:45:43.942Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-01-05",
        profilePic: null,
        qrCodeValue: null,
        startDate: null,
        id: "1b678ab65e745b6f991d8297e82300e2f56330c1",
    },
    {
        _id: {
            $oid: "63b69dbae5ccf1e5e2e963cb",
        },
        name: "Abhishek Kumar Mandal",
        designation: "Automation and DevOps Engineer",
        certificate_id: "1317c1c2ca82e86474f9432b95b237324f89b46f",
        issue_date: "2023-01-05",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-01-05T09:51:54.182Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-01-05",
        profilePic: null,
        qrCodeValue: null,
        startDate: null,
        id: "1317c1c2ca82e86474f9432b95b237324f89b46f",
    },
    {
        _id: {
            $oid: "63e5da995f68445df9336ceb",
        },
        name: "Aditya Pradhan",
        designation: "UI/UX and Graphic Designer",
        profile_pic:
            "https://firebasestorage.googleapis.com/v0/b/squbix-admin.appspot.com/o/interns_profile_pic%2FWhatsApp%20Image%202023-02-10%20at%2010.50.36%20AM.jpeg?alt=media&token=9563745e-a4b9-4f3e-b945-888bd5ad6120",
        certificate_id: "72b511a87acb7f20d930c7ebecf0c71651a1fefe",
        issue_date: "2023-02-05",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-02-10T05:48:09.495Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-02-05",
        profilePic:
            "https://firebasestorage.googleapis.com/v0/b/squbix-admin.appspot.com/o/interns_profile_pic%2FWhatsApp%20Image%202023-02-10%20at%2010.50.36%20AM.jpeg?alt=media&token=9563745e-a4b9-4f3e-b945-888bd5ad6120",
        qrCodeValue: null,
        startDate: null,
        id: "72b511a87acb7f20d930c7ebecf0c71651a1fefe",
    },
    {
        _id: {
            $oid: "63b69da0e5ccf1e5e2e963c8",
        },
        name: "Bijay Jena",
        designation: "Mobile App Developer",
        certificate_id: "37d5179a3b97c79a053b4d0435bcc724683e8c27",
        issue_date: "2023-01-05",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-01-05T09:51:28.688Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-01-05",
        profilePic: null,
        qrCodeValue: null,
        startDate: null,
        id: "37d5179a3b97c79a053b4d0435bcc724683e8c27",
    },
    {
        _id: {
            $oid: "646b42dcb3058d919a93cd07",
        },
        name: "Sonam Maheswari Jena",
        designation: "React.js Developer",
        profile_pic: "",
        certificate_id: "392dd715eed58142ef111087f3f87720750a7bdf",
        issue_date: "2023-05-24",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-05-22T10:24:28.288Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-05-24",
        profilePic: "",
        qrCodeValue: null,
        startDate: null,
        id: "392dd715eed58142ef111087f3f87720750a7bdf",
    },
    {
        _id: {
            $oid: "6338889ac484ead2bc190c33",
        },
        name: "Anakha Krishna",
        designation: "Backend Developer Intern",
        certificate_id: "nfAPLL1wLYlcKCJB2WN0",
        issue_date: "7th September, 2021",
        issuer: "Squbix Digital Pte. Ltd.",
        type: "Internship Certificate",
        timestamp: {
            $date: "2022-10-01T18:36:10.688Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "7th September, 2021",
        profilePic: null,
        qrCodeValue: null,
        startDate: null,
        id: "nfAPLL1wLYlcKCJB2WN0",
    },
    {
        _id: {
            $oid: "63b69d3be5ccf1e5e2e963bc",
        },
        name: "Sumeet Naik",
        designation: "Backend/Cloud Developer",
        certificate_id: "14bbde669c23a14baa89b2c129c48ddb4e2a34e2",
        issue_date: "2023-01-05",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-01-05T09:49:47.269Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-01-05",
        profilePic: null,
        qrCodeValue: null,
        startDate: null,
        id: "14bbde669c23a14baa89b2c129c48ddb4e2a34e2",
    },
    {
        _id: {
            $oid: "646b43dfb3058d919a93cd16",
        },
        name: "Akash Pandit",
        designation: "Fullstack Developer",
        profile_pic: "",
        certificate_id: "584c55655b814b253ee07885d375aff746870a3f",
        issue_date: "2023-05-24",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-05-22T10:28:47.859Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-05-24",
        profilePic: "",
        qrCodeValue: null,
        startDate: null,
        id: "584c55655b814b253ee07885d375aff746870a3f",
    },
    {
        _id: {
            $oid: "63b69d57e5ccf1e5e2e963bf",
        },
        name: "Tanushree Sahoo",
        designation: "Backend/Cloud Developer",
        certificate_id: "ae493d48bd9c6e60ce39108e476f82703ff1ed64",
        issue_date: "2023-01-05",
        issuer: "Squbix Digital",
        type: "Internship Certificate",
        timestamp: {
            $date: "2023-01-05T09:50:15.669Z",
        },
        __v: 0,
        branch: null,
        college: null,
        endDate: null,
        issueDate: "2023-01-05",
        profilePic: null,
        qrCodeValue: null,
        startDate: null,
        id: "ae493d48bd9c6e60ce39108e476f82703ff1ed64",
    },
    {
        _id: {
            $oid: "66337871631174e6bcbdda3f",
        },
        name: "RAJESH ROSHAN PAL",
        designation: "BACK-END WEB DEVELOPMENT",
        college: "TRIDENT ACADEMY OF TECHNOLOGY",
        branch: "COMPUTER SCIENCE & INFORMATION TECHNOLOGY",
        id: "0af24c70-48ba-415e-a8e3-93cc5de7d8ef",
        qrCodeValue:
            "https://www.squbix.com/certificate/0af24c70-48ba-415e-a8e3-93cc5de7d8ef",
        issueDate: "2024-05-02",
        startDate: "2024-02-01",
        endDate: "2024-04-30",
        timestamp: {
            $date: "2024-05-02T11:26:41.300Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "6633794b631174e6bcbdda47",
        },
        name: "RITIK RAJ",
        designation: "BACK-END WEB DEVELOPMENT",
        college: "TRIDENT ACADEMY OF TECHNOLOGY",
        branch: "COMPUTER SCIENCE & INFORMATION TECHNOLOGY",
        id: "9a9ca77e-5796-4bff-8342-23573627e8cb",
        qrCodeValue:
            "https://www.squbix.com/certificate/9a9ca77e-5796-4bff-8342-23573627e8cb",
        issueDate: "2024-05-02",
        startDate: "2024-02-01",
        endDate: "2024-04-30",
        timestamp: {
            $date: "2024-05-02T11:30:19.446Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "6633798a631174e6bcbdda4b",
        },
        name: "BIKRAM BEHERA",
        designation: "BACK-END WEB DEVELOPMENT",
        college: "TRIDENT ACADEMY OF TECHNOLOGY",
        branch: "COMPUTER SCIENCE & INFORMATION TECHNOLOGY",
        id: "260223e2-40cb-4ece-9bbc-71cd4a4239d7",
        qrCodeValue:
            "https://www.squbix.com/certificate/260223e2-40cb-4ece-9bbc-71cd4a4239d7",
        issueDate: "2024-05-02",
        startDate: "2024-02-01",
        endDate: "2024-04-30",
        timestamp: {
            $date: "2024-05-02T11:31:22.460Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "663379e0631174e6bcbdda4f",
        },
        name: "SUSHREE NIHARIKA DASH",
        designation: "BACK-END WEB DEVELOPMENT",
        college: "TRIDENT ACADEMY OF TECHNOLOGY",
        branch: "COMPUTER SCIENCE & INFORMATION TECHNOLOGY",
        id: "6ae9eeb7-305f-4100-bcda-202cca3843b4",
        qrCodeValue:
            "https://www.squbix.com/certificate/6ae9eeb7-305f-4100-bcda-202cca3843b4",
        issueDate: "2024-05-02",
        startDate: "2024-02-01",
        endDate: "2024-04-30",
        timestamp: {
            $date: "2024-05-02T11:32:48.150Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "6634b72f631174e6bcbddb39",
        },
        name: "ADYASHA JENA",
        designation: "BACK-END WEB DEVELOPMENT",
        college: "TRIDENT ACADEMY OF TECHNOLOGY",
        branch: "COMPUTER SCIENCE & ENGINEERING",
        id: "41e3a80a-c527-4731-9318-6849a1df8a2d",
        qrCodeValue:
            "https://www.squbix.com/certificate/41e3a80a-c527-4731-9318-6849a1df8a2d",
        issueDate: "2024-05-03",
        startDate: "2024-02-01",
        endDate: "2024-04-30",
        timestamp: {
            $date: "2024-05-03T10:06:39.699Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "6634b88f631174e6bcbddb3e",
        },
        name: "ARADHANA TRIPATHY",
        designation: "BACK-END WEB DEVELOPMENT",
        college: "TRIDENT ACADEMY OF TECHNOLOGY",
        branch: "COMPUTER SCIENCE & ENGINEERING",
        id: "bac58405-c6d5-43e5-b1d3-1b8623c02b96",
        qrCodeValue:
            "https://www.squbix.com/certificate/bac58405-c6d5-43e5-b1d3-1b8623c02b96",
        issueDate: "2024-05-03",
        startDate: "2024-02-01",
        endDate: "2024-04-30",
        timestamp: {
            $date: "2024-05-03T10:12:31.968Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "6634b95b631174e6bcbddb43",
        },
        name: "AKSHITA MISHRA",
        designation: "BACK-END WEB DEVELOPMENT",
        college: "TRIDENT ACADEMY OF TECHNOLOGY",
        branch: "COMPUTER SCIENCE & ENGINEERING",
        id: "2b5ea813-2474-4af8-80f8-52152759de29",
        qrCodeValue:
            "https://www.squbix.com/certificate/2b5ea813-2474-4af8-80f8-52152759de29",
        issueDate: "2024-05-03",
        startDate: "2024-02-01",
        endDate: "2024-04-30",
        timestamp: {
            $date: "2024-05-03T10:15:55.215Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "6634bd0d631174e6bcbddb65",
        },
        name: "AMIT KUMAR SWAIN",
        designation: "BACK-END WEB DEVELOPMENT",
        college: "TRIDENT ACADEMY OF TECHNOLOGY",
        branch: "COMPUTER SCIENCE & ENGINEERING",
        id: "c66c8d27-1957-4072-8e4f-0ec9bde3b530",
        qrCodeValue:
            "https://www.squbix.com/certificate/c66c8d27-1957-4072-8e4f-0ec9bde3b530",
        issueDate: "2024-05-03",
        startDate: "2024-02-01",
        endDate: "2024-04-30",
        timestamp: {
            $date: "2024-05-03T10:31:41.985Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "664c69ee631174e6bcbddc69",
        },
        name: "AMLAN JYOTI PRAKASH",
        designation: "UI/UX DESIGN",
        college: "SILICON UNIVERSITY, BHUBANESWAR",
        branch: "COMPUTER SCIENCE & ENGINEERING",
        id: "0eb6a491-6969-4e22-bfa2-7f26366f10cb",
        qrCodeValue:
            "https://www.squbix.com/certificate/0eb6a491-6969-4e22-bfa2-7f26366f10cb",
        issueDate: "2024-05-21",
        startDate: "2024-01-17",
        endDate: "2024-06-30",
        timestamp: {
            $date: "2024-05-21T09:31:26.049Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "664c6af4631174e6bcbddc72",
        },
        name: "SHIVAM UPADHYAY",
        designation: "FRONTEND DEVELOPER INTERN",
        college: "SILICON UNIVERSITY",
        branch: "COMPUTER SCIENCE & ENGINEERING",
        id: "93eb60c7-bbef-4a5a-8341-7dea66bc807b",
        qrCodeValue:
            "https://www.squbix.com/certificate/93eb60c7-bbef-4a5a-8341-7dea66bc807b",
        issueDate: "2024-05-21",
        startDate: "2024-01-21",
        endDate: "2024-07-30",
        timestamp: {
            $date: "2024-05-21T09:35:48.265Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "664c6e06631174e6bcbddc85",
        },
        name: "AMLAN JYOTI PRAKASH",
        designation: "UI/UX DESIGNER",
        college: "SILICON UNIVERSITY",
        branch: "COMPUTER SCIENCE & ENGINEERING",
        id: "077fd7f4-475e-4a93-a435-66802e35035d",
        qrCodeValue:
            "https://www.squbix.com/certificate/077fd7f4-475e-4a93-a435-66802e35035d",
        issueDate: "2024-05-21",
        startDate: "2024-01-16",
        endDate: "2024-07-31",
        timestamp: {
            $date: "2024-05-21T09:48:54.498Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "664c6e7a631174e6bcbddc8a",
        },
        name: "Subrana Keshari Sutar",
        designation: "Front-end Web Development",
        college: "Silicon University, Bhubaneswar",
        branch: "Computer Science & Engineering",
        id: "b2263f84-571d-42bc-afe2-d8ae2e14a45e",
        qrCodeValue:
            "https://www.squbix.com/certificate/b2263f84-571d-42bc-afe2-d8ae2e14a45e",
        issueDate: "2024-05-21",
        startDate: "2024-01-17",
        endDate: "2024-07-30",
        timestamp: {
            $date: "2024-05-21T09:50:50.031Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "6667f771631174e6bcbddcf8",
        },
        name: "SHIVAM UPADHYAY",
        designation: "FRONT-END WEB DEVELOPER",
        college: "SILICON UNIVERSITY",
        branch: "COMPUTER SCIENCE & ENGINEERING",
        id: "348bfbd1-cb62-4a98-a905-b29052a318ce",
        qrCodeValue:
            "https://www.squbix.com/certificate/348bfbd1-cb62-4a98-a905-b29052a318ce",
        issueDate: "2024-06-11",
        startDate: "2024-01-15",
        endDate: "2024-05-31",
        timestamp: {
            $date: "2024-06-11T07:06:25.574Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f293c08b2cb1227fcb4886",
        },
        name: "dkdkn",
        designation: "kcdk",
        id: "5430a18f-7742-4e86-8312-6a10c17039bc",
        issueDate: "2024-09-25",
        qrCodeValue:
            "https://www.squbix.com/certificate/5430a18f-7742-4e86-8312-6a10c17039bc",
        endDate: "2024-09-24",
        startDate: "2024-09-24",
        college: "ssc",
        branch: "kcdk",
        timestamp: {
            $date: "2024-09-24T10:26:08.442Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c0438b2cb1227fcb48ce",
        },
        name: "AMARJEET  PAUL",
        designation: "Front-End Web Application Development",
        id: "4705803b-161a-4fc9-8656-fa81b5841f14",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/4705803b-161a-4fc9-8656-fa81b5841f14",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Engineering",
        timestamp: {
            $date: "2024-09-24T13:36:03.703Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c2338b2cb1227fcb48d9",
        },
        name: "PRATHANA  RAY",
        designation: "Front-End Web Application Development",
        id: "2684f0dd-4bb4-4f28-88f5-ad2eb447a334",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/2684f0dd-4bb4-4f28-88f5-ad2eb447a334",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Engineering",
        timestamp: {
            $date: "2024-09-24T13:44:19.735Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c2408b2cb1227fcb48dc",
        },
        name: "PRITISH  MISHRA",
        designation: "Front-End Web Application Development",
        id: "7c3e4074-488b-4a9c-b9f5-270fdd5a57db",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/7c3e4074-488b-4a9c-b9f5-270fdd5a57db",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Engineering",
        timestamp: {
            $date: "2024-09-24T13:44:32.306Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c24c8b2cb1227fcb48df",
        },
        name: "RITUNANDA  GIRI",
        designation: "Front-End Web Application Development",
        id: "b4ae0278-bf03-45b5-95f7-cb4d0e7c79ac",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/b4ae0278-bf03-45b5-95f7-cb4d0e7c79ac",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Engineering",
        timestamp: {
            $date: "2024-09-24T13:44:44.300Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c2598b2cb1227fcb48e2",
        },
        name: "SUPRIYA  SAHOO",
        designation: "Front-End Web Application Development",
        id: "6beef148-dbb2-4498-9503-d0b03204ec9f",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/6beef148-dbb2-4498-9503-d0b03204ec9f",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Engineering",
        timestamp: {
            $date: "2024-09-24T13:44:57.413Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c2668b2cb1227fcb48e5",
        },
        name: "ABHIPSA  PATTNAIK",
        designation: "Front-End Web Application Development",
        id: "8c1ad09d-f703-4e81-8f6f-eb99d69c636a",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/8c1ad09d-f703-4e81-8f6f-eb99d69c636a",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:45:10.733Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c2748b2cb1227fcb48e8",
        },
        name: "ADITYA  SHARMA",
        designation: "Front-End Web Application Development",
        id: "1a96b80d-7010-4767-85e9-7770fd079ac0",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/1a96b80d-7010-4767-85e9-7770fd079ac0",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:45:24.781Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c2838b2cb1227fcb48eb",
        },
        name: "ANIL KUMAR  NAYAK",
        designation: "Front-End Web Application Development",
        id: "f646dd5a-7cd3-4d9d-b81c-2fdd5fc69b6c",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/f646dd5a-7cd3-4d9d-b81c-2fdd5fc69b6c",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:45:39.532Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c2928b2cb1227fcb48ee",
        },
        name: "ANUBHAV  MOHANTY",
        designation: "Front-End Web Application Development",
        id: "b3bb1e25-d683-4dfd-ae3a-4a2475929c5c",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/b3bb1e25-d683-4dfd-ae3a-4a2475929c5c",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:45:54.969Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c2a18b2cb1227fcb48f1",
        },
        name: "ARIJIT  MOHANTY",
        designation: "Front-End Web Application Development",
        id: "8e09a687-5aa1-407a-bfd6-bacfff5d38f4",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/8e09a687-5aa1-407a-bfd6-bacfff5d38f4",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:46:09.196Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c2b08b2cb1227fcb48f4",
        },
        name: "MD TAMEEZ ALAM",
        designation: "Front-End Web Application Development",
        id: "493014ca-5fbc-40f0-bd87-f47c5c039c96",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/493014ca-5fbc-40f0-bd87-f47c5c039c96",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:46:24.569Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c2c08b2cb1227fcb48f7",
        },
        name: "MONALISHA  BAG",
        designation: "Front-End Web Application Development",
        id: "a97c75f1-892c-4f76-9f27-b71e83f8b62c",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/a97c75f1-892c-4f76-9f27-b71e83f8b62c",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:46:40.356Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c2d28b2cb1227fcb48fa",
        },
        name: "PERECHERLA ANANT VARMA",
        designation: "Front-End Web Application Development",
        id: "bd488636-944e-470e-a7ac-0206e2686eda",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/bd488636-944e-470e-a7ac-0206e2686eda",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:46:58.666Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c2e38b2cb1227fcb48fd",
        },
        name: "RAJ  SHREE",
        designation: "Front-End Web Application Development",
        id: "e07ae989-90ab-474d-a9b6-c14b51cab943",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/e07ae989-90ab-474d-a9b6-c14b51cab943",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:47:15.555Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c2f48b2cb1227fcb4900",
        },
        name: "RANAK  KUMAR PRADHAN",
        designation: "Front-End Web Application Development",
        id: "a30d8fe2-5136-444c-ad2e-76bf230e97a0",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/a30d8fe2-5136-444c-ad2e-76bf230e97a0",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:47:32.054Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c3048b2cb1227fcb4903",
        },
        name: "SATYAM  SAHOO",
        designation: "Front-End Web Application Development",
        id: "fc6cbbf7-6583-4f6c-a527-6957b8f261d0",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/fc6cbbf7-6583-4f6c-a527-6957b8f261d0",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:47:48.475Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c3148b2cb1227fcb4906",
        },
        name: "SHRUTI  KUMARI",
        designation: "Front-End Web Application Development",
        id: "b4042186-1598-401c-98d4-df909e1005aa",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/b4042186-1598-401c-98d4-df909e1005aa",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:48:04.952Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c3268b2cb1227fcb4909",
        },
        name: "SMARANIKA  SETHY",
        designation: "Front-End Web Application Development",
        id: "c18f29f8-f1ef-490f-8936-6b2607079e80",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/c18f29f8-f1ef-490f-8936-6b2607079e80",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:48:22.751Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c33c8b2cb1227fcb490c",
        },
        name: "AMARJEET  SAHOO",
        designation: "Front-End Web Application Development",
        id: "22ba9740-d86f-4d75-a0b7-768ff18d162a",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/22ba9740-d86f-4d75-a0b7-768ff18d162a",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:48:44.434Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c34e8b2cb1227fcb490f",
        },
        name: "BISHAKHA  MUDULI",
        designation: "Front-End Web Application Development",
        id: "db9eacb6-76f8-45e7-bd09-738fea7931ee",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/db9eacb6-76f8-45e7-bd09-738fea7931ee",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:49:02.305Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c35d8b2cb1227fcb4912",
        },
        name: "LOPA  PATANAIK",
        designation: "Front-End Web Application Development",
        id: "5c726e98-50cf-42ff-b191-1a0ac8f2817b",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/5c726e98-50cf-42ff-b191-1a0ac8f2817b",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:49:17.054Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c36b8b2cb1227fcb4915",
        },
        name: "NILESH RANJAN NAYAK",
        designation: "Front-End Web Application Development",
        id: "fc2f3ae1-6a5f-40ed-90a6-6621a556c7af",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/fc2f3ae1-6a5f-40ed-90a6-6621a556c7af",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:49:31.219Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c3798b2cb1227fcb4918",
        },
        name: "PRADIP KUMAR MUKHI",
        designation: "Front-End Web Application Development",
        id: "8de690ae-a8fd-46e2-a73b-42cdb13445db",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/8de690ae-a8fd-46e2-a73b-42cdb13445db",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:49:45.915Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c3888b2cb1227fcb491b",
        },
        name: "ROBIN  KUMAR",
        designation: "Front-End Web Application Development",
        id: "63dd00b6-51c3-46b2-b381-4208af2a98d8",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/63dd00b6-51c3-46b2-b381-4208af2a98d8",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:50:00.532Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c3978b2cb1227fcb491e",
        },
        name: "SREEANSH  MOHANTY",
        designation: "Front-End Web Application Development",
        id: "bb49eb93-eb3d-4f0b-bbb6-f33fd11c4c67",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/bb49eb93-eb3d-4f0b-bbb6-f33fd11c4c67",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:50:15.546Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c3a78b2cb1227fcb4921",
        },
        name: "SUBHAM SATYAPRAKASH SAHOO",
        designation: "Front-End Web Application Development",
        id: "81473c64-21ba-424c-a38f-309cbd1776bb",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/81473c64-21ba-424c-a38f-309cbd1776bb",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:50:31.399Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c3b68b2cb1227fcb4924",
        },
        name: "TAPASWINI  GANTAYAT",
        designation: "Front-End Web Application Development",
        id: "1c5264b4-7845-46aa-a0ab-e9685a3f2533",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/1c5264b4-7845-46aa-a0ab-e9685a3f2533",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T13:50:46.773Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c3c68b2cb1227fcb4927",
        },
        name: "AYUSHEE   MALLICK",
        designation: "Front-End Web Application Development",
        id: "2221eb27-caa1-4fc3-b5d9-b62a4ebe9935",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/2221eb27-caa1-4fc3-b5d9-b62a4ebe9935",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Technology",
        timestamp: {
            $date: "2024-09-24T13:51:02.016Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c3d58b2cb1227fcb492a",
        },
        name: "BISWARANJAN  MOHANTY",
        designation: "Front-End Web Application Development",
        id: "adb83a9b-641d-4afd-86dc-fb166d4f5857",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/adb83a9b-641d-4afd-86dc-fb166d4f5857",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Technology",
        timestamp: {
            $date: "2024-09-24T13:51:17.609Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c3e58b2cb1227fcb492d",
        },
        name: "MANNAT  MISHRA",
        designation: "Front-End Web Application Development",
        id: "8bbf152a-0eaf-49ae-868b-d4c73bf208db",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/8bbf152a-0eaf-49ae-868b-d4c73bf208db",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Technology",
        timestamp: {
            $date: "2024-09-24T13:51:33.004Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c3f58b2cb1227fcb4930",
        },
        name: "SOUMYA   MOHANTY",
        designation: "Front-End Web Application Development",
        id: "566afa6c-c708-4a9d-ab4e-cf2cea1d9715",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/566afa6c-c708-4a9d-ab4e-cf2cea1d9715",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Technology",
        timestamp: {
            $date: "2024-09-24T13:51:49.028Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c4058b2cb1227fcb4933",
        },
        name: "SWAYAM  MOHAPATRA",
        designation: "Front-End Web Application Development",
        id: "a01d88a9-ccfb-4ee2-9a16-bfdb19ed3c48",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/a01d88a9-ccfb-4ee2-9a16-bfdb19ed3c48",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Technology",
        timestamp: {
            $date: "2024-09-24T13:52:05.707Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c4168b2cb1227fcb4936",
        },
        name: "VAISHNAVI  SUBHADARSINI",
        designation: "Front-End Web Application Development",
        id: "3ed0b7d0-899f-4778-a56a-98f3d158ac2a",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/3ed0b7d0-899f-4778-a56a-98f3d158ac2a",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Technology",
        timestamp: {
            $date: "2024-09-24T13:52:22.464Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c4278b2cb1227fcb4939",
        },
        name: "DHEERAJ KUMAR PATRA",
        designation: "Front-End Web Application Development",
        id: "67477a01-03c4-4a98-acb2-9cd343665eaa",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/67477a01-03c4-4a98-acb2-9cd343665eaa",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Technology",
        timestamp: {
            $date: "2024-09-24T13:52:39.252Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c4378b2cb1227fcb493c",
        },
        name: "SNEHA  PATRA",
        designation: "Front-End Web Application Development",
        id: "fe057d37-8f01-45b4-ae9f-ac552eec2082",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/fe057d37-8f01-45b4-ae9f-ac552eec2082",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Technology",
        timestamp: {
            $date: "2024-09-24T13:52:55.658Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c4478b2cb1227fcb493f",
        },
        name: "SOURAV KUMAR NANDA",
        designation: "Front-End Web Application Development",
        id: "d464a5a9-3eb8-4191-a94e-52bfb8a9cb23",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/d464a5a9-3eb8-4191-a94e-52bfb8a9cb23",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Data Science",
        timestamp: {
            $date: "2024-09-24T13:53:11.996Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c4588b2cb1227fcb4942",
        },
        name: "SWETAPRITI   PRADHAN",
        designation: "Front-End Web Application Development",
        id: "3ceb9b17-8d48-4d7f-8970-0404070b1049",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/3ceb9b17-8d48-4d7f-8970-0404070b1049",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Data Science",
        timestamp: {
            $date: "2024-09-24T13:53:28.167Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c46b8b2cb1227fcb4945",
        },
        name: "LOPAMUDRA   SWAIN",
        designation: "Front-End Web Application Development",
        id: "4eb863f0-a38e-412c-ac86-9de765437357",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/4eb863f0-a38e-412c-ac86-9de765437357",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electrical & Electronics Engineering",
        timestamp: {
            $date: "2024-09-24T13:53:47.983Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c47e8b2cb1227fcb4948",
        },
        name: "ABHIVAD  PATTANAIK",
        designation: "Front-End Web Application Development",
        id: "14b6b4c7-2449-45d5-8fad-7db9f6ce3021",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/14b6b4c7-2449-45d5-8fad-7db9f6ce3021",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electrical & Electronics Engineering",
        timestamp: {
            $date: "2024-09-24T13:54:06.718Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c4918b2cb1227fcb494b",
        },
        name: "ADARSH  AGRAWALA",
        designation: "Front-End Web Application Development",
        id: "0fcc4598-9914-462d-ae8c-f882f416f8af",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/0fcc4598-9914-462d-ae8c-f882f416f8af",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electrical & Electronics Engineering",
        timestamp: {
            $date: "2024-09-24T13:54:25.615Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c4a48b2cb1227fcb494e",
        },
        name: "ASHISH  JENA",
        designation: "Front-End Web Application Development",
        id: "f30a6937-8d53-4213-a22c-2ce48e62cfb5",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/f30a6937-8d53-4213-a22c-2ce48e62cfb5",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electrical & Electronics Engineering",
        timestamp: {
            $date: "2024-09-24T13:54:44.726Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c4b98b2cb1227fcb4951",
        },
        name: "NANDINI  JENAMONI",
        designation: "Front-End Web Application Development",
        id: "3d5d5efc-d513-4b6c-9a61-08b6cd62d2a9",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/3d5d5efc-d513-4b6c-9a61-08b6cd62d2a9",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electrical & Electronics Engineering",
        timestamp: {
            $date: "2024-09-24T13:55:05.528Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c4cf8b2cb1227fcb4956",
        },
        name: "RASHIKA  DAS",
        designation: "Front-End Web Application Development",
        id: "83605593-24ea-474e-9ce3-4549981edc90",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/83605593-24ea-474e-9ce3-4549981edc90",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electrical & Electronics Engineering",
        timestamp: {
            $date: "2024-09-24T13:55:27.528Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c4e48b2cb1227fcb495a",
        },
        name: "REETI MUKTA RATH",
        designation: "Front-End Web Application Development",
        id: "1d8360eb-1e62-43c0-be60-367e681f29db",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/1d8360eb-1e62-43c0-be60-367e681f29db",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electrical & Electronics Engineering",
        timestamp: {
            $date: "2024-09-24T13:55:48.374Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c4f88b2cb1227fcb495e",
        },
        name: "SHUBHASMITA   DASH",
        designation: "Front-End Web Application Development",
        id: "9b62a7af-ef5f-485f-b484-5ba82261f275",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/9b62a7af-ef5f-485f-b484-5ba82261f275",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electrical & Electronics Engineering",
        timestamp: {
            $date: "2024-09-24T13:56:08.679Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c50d8b2cb1227fcb4961",
        },
        name: "JIVAN JYOTI PAL",
        designation: "Front-End Web Application Development",
        id: "a67a3a8a-dcee-4e4b-b4cf-81169ba7a7af",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/a67a3a8a-dcee-4e4b-b4cf-81169ba7a7af",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electrical & Electronics Engineering",
        timestamp: {
            $date: "2024-09-24T13:56:29.248Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c5238b2cb1227fcb4964",
        },
        name: "SANDEEP SOUMYA RANJAN SAHOO",
        designation: "Front-End Web Application Development",
        id: "93475610-2155-4833-9a21-d904ff963922",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/93475610-2155-4833-9a21-d904ff963922",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electrical & Electronics Engineering",
        timestamp: {
            $date: "2024-09-24T13:56:51.479Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c5388b2cb1227fcb4967",
        },
        name: "SUBHRAJIT  MOHAPATRA",
        designation: "Front-End Web Application Development",
        id: "9a4b84a2-8076-43b1-a6b2-a0217c2ed87f",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/9a4b84a2-8076-43b1-a6b2-a0217c2ed87f",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electrical & Electronics Engineering",
        timestamp: {
            $date: "2024-09-24T13:57:12.724Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c5508b2cb1227fcb496a",
        },
        name: "SNEHANSU SEKHAR MISHRA",
        designation: "Front-End Web Application Development",
        id: "72495321-c66b-4887-a637-43cda26acb9b",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/72495321-c66b-4887-a637-43cda26acb9b",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T13:57:36.478Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c5688b2cb1227fcb496d",
        },
        name: "ANINDITA  PARIDA",
        designation: "Front-End Web Application Development",
        id: "7cbaf0b3-cc4b-4bfb-8791-357ac3c94f49",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/7cbaf0b3-cc4b-4bfb-8791-357ac3c94f49",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T13:58:00.462Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c57f8b2cb1227fcb4970",
        },
        name: "ANKITA  DAS",
        designation: "Front-End Web Application Development",
        id: "124ded28-9838-462d-98ca-3723c890f85b",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/124ded28-9838-462d-98ca-3723c890f85b",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T13:58:23.214Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c5958b2cb1227fcb4973",
        },
        name: "ANKIT KUMAR JENA",
        designation: "Front-End Web Application Development",
        id: "18dc80d8-476f-4b73-b086-3a8d36c76b3a",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/18dc80d8-476f-4b73-b086-3a8d36c76b3a",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T13:58:45.697Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c5ae8b2cb1227fcb4976",
        },
        name: "ARCHANA  DEVI",
        designation: "Front-End Web Application Development",
        id: "2132d603-76c1-4362-a8be-7d108d0b0cbd",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/2132d603-76c1-4362-a8be-7d108d0b0cbd",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T13:59:10.677Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c5c58b2cb1227fcb4979",
        },
        name: "BAISHNA  PATTNAIK",
        designation: "Front-End Web Application Development",
        id: "0013ddaf-a428-4ea3-8deb-2b4ece512e42",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/0013ddaf-a428-4ea3-8deb-2b4ece512e42",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T13:59:33.704Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c5dd8b2cb1227fcb497c",
        },
        name: "DEBJANI  SAHU",
        designation: "Front-End Web Application Development",
        id: "f18c17eb-2e53-40a7-9064-c80464b4eea5",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/f18c17eb-2e53-40a7-9064-c80464b4eea5",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T13:59:57.143Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c5f58b2cb1227fcb497f",
        },
        name: "DIBYA  SHANKAR",
        designation: "Front-End Web Application Development",
        id: "2b02b348-f2ac-47b3-abe0-ca11a597b04c",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/2b02b348-f2ac-47b3-abe0-ca11a597b04c",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:00:21.521Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c60e8b2cb1227fcb4982",
        },
        name: "HRITESH  KUMAR SAHOO",
        designation: "Front-End Web Application Development",
        id: "094b0ac6-329e-4a5d-ac4b-55af80ed178c",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/094b0ac6-329e-4a5d-ac4b-55af80ed178c",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:00:46.702Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c62b8b2cb1227fcb4985",
        },
        name: "MEGHA  PATRO",
        designation: "Front-End Web Application Development",
        id: "aa12313d-a216-4724-80c5-0181c3917b32",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/aa12313d-a216-4724-80c5-0181c3917b32",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:01:15.168Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c6448b2cb1227fcb4988",
        },
        name: "MUKTI  GOUDA",
        designation: "Front-End Web Application Development",
        id: "cf7350ff-d112-4867-ba8c-a25475ab8f77",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/cf7350ff-d112-4867-ba8c-a25475ab8f77",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:01:40.620Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c65f8b2cb1227fcb498b",
        },
        name: "PRITAM PRIYANSHU PATRA",
        designation: "Front-End Web Application Development",
        id: "fbdfee80-bfea-43ec-8884-2ae6a28f461d",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/fbdfee80-bfea-43ec-8884-2ae6a28f461d",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:02:07.702Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c67b8b2cb1227fcb498e",
        },
        name: "PRIYANSHA RANI PANDA",
        designation: "Front-End Web Application Development",
        id: "6a2deac6-f3bf-45bc-aa82-6ac4dbd7e3f6",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/6a2deac6-f3bf-45bc-aa82-6ac4dbd7e3f6",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:02:35.618Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c6968b2cb1227fcb4991",
        },
        name: "RITISHA  DASH",
        designation: "Front-End Web Application Development",
        id: "612b4ea5-3869-4d8b-b074-3f74dbb6c97a",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/612b4ea5-3869-4d8b-b074-3f74dbb6c97a",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:03:02.727Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c6b48b2cb1227fcb4994",
        },
        name: "SAI  KIRAN",
        designation: "Front-End Web Application Development",
        id: "2aef1a9e-eeba-4bd5-b927-efe05e9cc3a4",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/2aef1a9e-eeba-4bd5-b927-efe05e9cc3a4",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:03:32.996Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c6d28b2cb1227fcb4997",
        },
        name: "SAIPRITI  ROUT",
        designation: "Front-End Web Application Development",
        id: "dfbad4c8-ef18-4ab2-b094-1ff0de82325f",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/dfbad4c8-ef18-4ab2-b094-1ff0de82325f",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:04:02.329Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c6ee8b2cb1227fcb499a",
        },
        name: "SHUBHAM KUMAR JHA",
        designation: "Front-End Web Application Development",
        id: "9cfbd2e0-80aa-4a3b-858e-31bd697bb037",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/9cfbd2e0-80aa-4a3b-858e-31bd697bb037",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:04:30.868Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c70a8b2cb1227fcb499d",
        },
        name: "SITIKANTHA  DALAL",
        designation: "Front-End Web Application Development",
        id: "f0f3a73e-4e10-42c0-84b4-33dd8cf63c7c",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/f0f3a73e-4e10-42c0-84b4-33dd8cf63c7c",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:04:58.892Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c7288b2cb1227fcb49a0",
        },
        name: "SMARAKI  SATAPATHY",
        designation: "Front-End Web Application Development",
        id: "77085951-6eb6-4ab4-b995-d40661fc1e37",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/77085951-6eb6-4ab4-b995-d40661fc1e37",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:05:28.775Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c7468b2cb1227fcb49a3",
        },
        name: "SOUMYASHREE  SAHOO",
        designation: "Front-End Web Application Development",
        id: "e312eeb4-0b7a-4a94-b075-96c7162dce71",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/e312eeb4-0b7a-4a94-b075-96c7162dce71",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:05:58.953Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c7638b2cb1227fcb49a6",
        },
        name: "SWAGAT  NAYAK",
        designation: "Front-End Web Application Development",
        id: "f3b404fe-9586-4648-a3a2-8e66342de039",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/f3b404fe-9586-4648-a3a2-8e66342de039",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:06:27.813Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c7818b2cb1227fcb49a9",
        },
        name: "ADITI  SHARMA",
        designation: "Front-End Web Application Development",
        id: "03ff61da-5e2e-4979-875f-5fed775855cf",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/03ff61da-5e2e-4979-875f-5fed775855cf",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:06:57.726Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c7a38b2cb1227fcb49ac",
        },
        name: "DIBYAJYOTI  MOHANTY",
        designation: "Front-End Web Application Development",
        id: "78ac086f-fd1b-4533-8dbf-d979c05daea6",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/78ac086f-fd1b-4533-8dbf-d979c05daea6",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:07:31.023Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c7c28b2cb1227fcb49af",
        },
        name: "PUSHPANJALI  KUMARI",
        designation: "Front-End Web Application Development",
        id: "a2bbd8ae-5213-4e30-b5d7-29baae21d7c9",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/a2bbd8ae-5213-4e30-b5d7-29baae21d7c9",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:08:02.103Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c7e78b2cb1227fcb49b2",
        },
        name: "SAURAV  MISHRA",
        designation: "Front-End Web Application Development",
        id: "7b297a11-bfc6-45dc-8ecd-60f7f01ac6c2",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/7b297a11-bfc6-45dc-8ecd-60f7f01ac6c2",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:08:39.178Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c80c8b2cb1227fcb49b5",
        },
        name: "SRUTI SUCHARITA SWAIN",
        designation: "Front-End Web Application Development",
        id: "b0174368-96bb-48b9-a6c6-4580df2e9307",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/b0174368-96bb-48b9-a6c6-4580df2e9307",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics & Communication Engineering",
        timestamp: {
            $date: "2024-09-24T14:09:16.946Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c82e8b2cb1227fcb49b8",
        },
        name: "DIPIKA  SAHOO",
        designation: "Front-End Web Application Development",
        id: "455eeb0b-b514-4527-9858-870223bfb496",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/455eeb0b-b514-4527-9858-870223bfb496",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics and  instrumentation Engineering",
        timestamp: {
            $date: "2024-09-24T14:09:50.102Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c84f8b2cb1227fcb49bb",
        },
        name: "NIKITA  PANIGRAHI",
        designation: "Front-End Web Application Development",
        id: "30242dd7-69fc-4225-be23-fc1e1647290c",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/30242dd7-69fc-4225-be23-fc1e1647290c",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics and  instrumentation Engineering",
        timestamp: {
            $date: "2024-09-24T14:10:23.393Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c8768b2cb1227fcb49be",
        },
        name: "PUJA PRIYADARSHANI DAS",
        designation: "Front-End Web Application Development",
        id: "109acb74-6bdb-4501-8169-4d437a0aff40",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/109acb74-6bdb-4501-8169-4d437a0aff40",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics and  instrumentation Engineering",
        timestamp: {
            $date: "2024-09-24T14:11:02.187Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c89c8b2cb1227fcb49c1",
        },
        name: "S N PARSURAM SWAIN",
        designation: "Front-End Web Application Development",
        id: "d27c969f-8d8c-44ca-85b4-90d2995304c5",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/d27c969f-8d8c-44ca-85b4-90d2995304c5",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Electronics and  instrumentation Engineering",
        timestamp: {
            $date: "2024-09-24T14:11:40.599Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c8be8b2cb1227fcb49c4",
        },
        name: "ABHAYA KUMAR DAS",
        designation: "Front-End Web Application Development",
        id: "70ffc85c-9667-43c9-9199-f12a9825d997",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/70ffc85c-9667-43c9-9199-f12a9825d997",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:12:14.090Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c8e98b2cb1227fcb49c7",
        },
        name: "AKANKSHYA  DAS",
        designation: "Front-End Web Application Development",
        id: "e39085c4-d87b-47c1-8ffc-8629e5f439be",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/e39085c4-d87b-47c1-8ffc-8629e5f439be",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:12:57.342Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c90a8b2cb1227fcb49ca",
        },
        name: "ALISA  ORAM",
        designation: "Front-End Web Application Development",
        id: "8ddbabd6-5056-4062-9949-247ade0ae19f",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/8ddbabd6-5056-4062-9949-247ade0ae19f",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:13:30.174Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c9348b2cb1227fcb49cd",
        },
        name: "ANILA  SAHU",
        designation: "Front-End Web Application Development",
        id: "37b16f57-397b-4a29-941a-393f120f9b40",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/37b16f57-397b-4a29-941a-393f120f9b40",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:14:12.299Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c9568b2cb1227fcb49d0",
        },
        name: "ANURAG  PARIDA",
        designation: "Front-End Web Application Development",
        id: "1426da89-bc8b-4e26-bc15-afc69aa8e316",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/1426da89-bc8b-4e26-bc15-afc69aa8e316",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:14:46.195Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c9788b2cb1227fcb49d3",
        },
        name: "CHITTARANJAN  BAL",
        designation: "Front-End Web Application Development",
        id: "1e672587-083f-4c13-b287-880a1100530a",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/1e672587-083f-4c13-b287-880a1100530a",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:15:20.328Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c99c8b2cb1227fcb49d6",
        },
        name: "DAMBARUDHAR  SAHOO",
        designation: "Front-End Web Application Development",
        id: "b332993a-a2b5-4d71-a734-c2d28f6bb322",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/b332993a-a2b5-4d71-a734-c2d28f6bb322",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:15:56.322Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c9c08b2cb1227fcb49d9",
        },
        name: "DEBASIS  PANIGRAHI",
        designation: "Front-End Web Application Development",
        id: "696b739d-df43-4c15-baa7-255a14002df2",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/696b739d-df43-4c15-baa7-255a14002df2",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:16:32.200Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2c9e78b2cb1227fcb49dc",
        },
        name: "DIBYASHREE  PRUSTY",
        designation: "Front-End Web Application Development",
        id: "3c4b33e2-eaa2-4a4d-9cf1-d156bc24291e",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/3c4b33e2-eaa2-4a4d-9cf1-d156bc24291e",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:17:11.378Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2ca0b8b2cb1227fcb49df",
        },
        name: "GURUPADA  PRADHAN",
        designation: "Front-End Web Application Development",
        id: "50257b37-26e7-4c7a-8a0a-af2df98558de",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/50257b37-26e7-4c7a-8a0a-af2df98558de",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:17:47.476Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2ca308b2cb1227fcb49e2",
        },
        name: "HRUSIKESH  PADHY",
        designation: "Front-End Web Application Development",
        id: "4ea65c24-caff-4bcf-b20b-f861f651d747",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/4ea65c24-caff-4bcf-b20b-f861f651d747",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:18:24.309Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2ca628b2cb1227fcb49e5",
        },
        name: "KUMAR GAURAV",
        designation: "Front-End Web Application Development",
        id: "a7d9276b-c6dc-414d-b610-a9dcd5f199f8",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/a7d9276b-c6dc-414d-b610-a9dcd5f199f8",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:19:14.917Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2ca998b2cb1227fcb49e8",
        },
        name: "MANDAKINI  RATH",
        designation: "Front-End Web Application Development",
        id: "4a0e77ca-b9ff-457a-9902-acaec624d158",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/4a0e77ca-b9ff-457a-9902-acaec624d158",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:20:09.413Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cabf8b2cb1227fcb49eb",
        },
        name: "MANJAYEE  RATH",
        designation: "Front-End Web Application Development",
        id: "ec072c6a-02a6-4149-b191-8ef96ccfe054",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/ec072c6a-02a6-4149-b191-8ef96ccfe054",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:20:47.746Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2caea8b2cb1227fcb49ee",
        },
        name: "MASHUMA  FARHEEN",
        designation: "Front-End Web Application Development",
        id: "a273ef67-3ad0-41eb-8cfc-03271ce806b2",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/a273ef67-3ad0-41eb-8cfc-03271ce806b2",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:21:30.051Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cb1b8b2cb1227fcb49f1",
        },
        name: "MONALISA  MISHRA",
        designation: "Front-End Web Application Development",
        id: "75983f8d-e3f0-41f2-85c6-756e5a8a91a0",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/75983f8d-e3f0-41f2-85c6-756e5a8a91a0",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:22:19.162Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cb418b2cb1227fcb49f4",
        },
        name: "NEHA  TABASSUM",
        designation: "Front-End Web Application Development",
        id: "7ac09845-6f48-491d-894b-08575d0efb04",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/7ac09845-6f48-491d-894b-08575d0efb04",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:22:57.420Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cb6c8b2cb1227fcb49f7",
        },
        name: "NIRANJAN  PANIGRAHI",
        designation: "Front-End Web Application Development",
        id: "dcaefc85-c877-4333-8111-6c4374365b97",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/dcaefc85-c877-4333-8111-6c4374365b97",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:23:40.574Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cb998b2cb1227fcb49fa",
        },
        name: "PRAJAKTA PRIYADARSHINI BEHERA",
        designation: "Front-End Web Application Development",
        id: "389b7b8e-e830-4a99-8594-85ec2aadabe3",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/389b7b8e-e830-4a99-8594-85ec2aadabe3",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:24:25.573Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cbc28b2cb1227fcb49fd",
        },
        name: "PRITI PRALIPTA ROUT",
        designation: "Front-End Web Application Development",
        id: "c1d18873-f029-4459-8387-480dbb8bcc4d",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/c1d18873-f029-4459-8387-480dbb8bcc4d",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:25:06.972Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cbef8b2cb1227fcb4a00",
        },
        name: "PRITISAMARPITA  SAHOO",
        designation: "Front-End Web Application Development",
        id: "7decb258-1b65-4d70-b513-bffa5fc15f7f",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/7decb258-1b65-4d70-b513-bffa5fc15f7f",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:25:51.553Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cc188b2cb1227fcb4a03",
        },
        name: "PUJA  MODI",
        designation: "Front-End Web Application Development",
        id: "e7adae18-c9a3-40ee-b3fd-34d3382f2ead",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/e7adae18-c9a3-40ee-b3fd-34d3382f2ead",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:26:32.804Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cc468b2cb1227fcb4a06",
        },
        name: "RANJEET   LENKA",
        designation: "Front-End Web Application Development",
        id: "87f773ef-7479-4ad3-bf7a-0abf135b5864",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/87f773ef-7479-4ad3-bf7a-0abf135b5864",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:27:18.671Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cc768b2cb1227fcb4a09",
        },
        name: "SANJIT KUMAR SAHU",
        designation: "Front-End Web Application Development",
        id: "e15cb113-36b6-4e9e-8252-c0d109d82da9",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/e15cb113-36b6-4e9e-8252-c0d109d82da9",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:28:06.589Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cca68b2cb1227fcb4a0c",
        },
        name: "SATARUPA  SAHOO",
        designation: "Front-End Web Application Development",
        id: "55b61d21-a8d7-42ab-acd6-e5eb5c351313",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/55b61d21-a8d7-42ab-acd6-e5eb5c351313",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:28:54.972Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2ccd78b2cb1227fcb4a0f",
        },
        name: "SATYARANJAN  SAHOO",
        designation: "Front-End Web Application Development",
        id: "e0606d3c-8ba3-42bc-8873-cc530c433961",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/e0606d3c-8ba3-42bc-8873-cc530c433961",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:29:43.387Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cd0a8b2cb1227fcb4a12",
        },
        name: "SMARANIKA  MOHAPATRA",
        designation: "Front-End Web Application Development",
        id: "271e2ff5-d73e-42ad-9c96-36db82d73d3e",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/271e2ff5-d73e-42ad-9c96-36db82d73d3e",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:30:34.183Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cd3a8b2cb1227fcb4a15",
        },
        name: "SMRUTI RANI DAS",
        designation: "Front-End Web Application Development",
        id: "da3b8958-9f80-418f-854f-e50195f20673",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/da3b8958-9f80-418f-854f-e50195f20673",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:31:22.912Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cd828b2cb1227fcb4a18",
        },
        name: "SOUBHAGYA RANJAN BISWAL",
        designation: "Front-End Web Application Development",
        id: "601c0069-3322-4868-8106-f87d29caaa7c",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/601c0069-3322-4868-8106-f87d29caaa7c",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:32:34.995Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cdb38b2cb1227fcb4a1b",
        },
        name: "SOUMENDRA KUMAR BEHERA",
        designation: "Front-End Web Application Development",
        id: "17d128a7-498d-41aa-9968-616c351c6fe0",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/17d128a7-498d-41aa-9968-616c351c6fe0",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:33:23.567Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cde88b2cb1227fcb4a1e",
        },
        name: "SOUMYASHREE KRISHNA ROUT",
        designation: "Front-End Web Application Development",
        id: "024d2dae-cba2-4629-a552-deba0349fe09",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/024d2dae-cba2-4629-a552-deba0349fe09",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:34:16.491Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2ce188b2cb1227fcb4a21",
        },
        name: "STITI PRANGYA NAYAK",
        designation: "Front-End Web Application Development",
        id: "64ecaf4d-ebfc-4d33-b66f-a884cb82c564",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/64ecaf4d-ebfc-4d33-b66f-a884cb82c564",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:35:04.291Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2ce4d8b2cb1227fcb4a24",
        },
        name: "SUBHALAXMI  PANDA",
        designation: "Front-End Web Application Development",
        id: "c98eb4b4-9a6c-4ba4-a797-f50eae9b5aca",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/c98eb4b4-9a6c-4ba4-a797-f50eae9b5aca",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:35:57.503Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2ce818b2cb1227fcb4a27",
        },
        name: "SUBRAT  HOTA",
        designation: "Front-End Web Application Development",
        id: "b87c4bb6-a28a-46c4-a2b4-502a07884225",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/b87c4bb6-a28a-46c4-a2b4-502a07884225",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:36:49.426Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2ceb98b2cb1227fcb4a2a",
        },
        name: "SUKUMAR SOURABH CHIKUN SAHOO",
        designation: "Front-End Web Application Development",
        id: "357f0bef-71f4-4e98-8c54-bae5be2865f3",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/357f0bef-71f4-4e98-8c54-bae5be2865f3",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:37:45.108Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2ceed8b2cb1227fcb4a2d",
        },
        name: "SWETA RANI DASH",
        designation: "Front-End Web Application Development",
        id: "de18b760-2bc2-4e05-8f4f-dfe88fec4713",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/de18b760-2bc2-4e05-8f4f-dfe88fec4713",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:38:37.793Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cf2f8b2cb1227fcb4a30",
        },
        name: "TANISA  SAHU",
        designation: "Front-End Web Application Development",
        id: "56fd356a-5e42-4075-a1ee-c6c68f51f916",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/56fd356a-5e42-4075-a1ee-c6c68f51f916",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:39:43.507Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cf6b8b2cb1227fcb4a33",
        },
        name: "TEJASWINI  PADHY",
        designation: "Front-End Web Application Development",
        id: "5dfd7c51-0a4f-4d5c-8d24-8b5a50c94b22",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/5dfd7c51-0a4f-4d5c-8d24-8b5a50c94b22",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:40:43.688Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cfa38b2cb1227fcb4a36",
        },
        name: "TUSHAR KUMAR SAHU",
        designation: "Front-End Web Application Development",
        id: "70ebf907-d128-449a-be5d-be52957b7aa4",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/70ebf907-d128-449a-be5d-be52957b7aa4",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:41:39.320Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2cfe68b2cb1227fcb4a39",
        },
        name: "VISHWAJEET KUMAR SINGH",
        designation: "Front-End Web Application Development",
        id: "2a7fe4cf-cf8c-499b-952b-1ec58b4d834d",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/2a7fe4cf-cf8c-499b-952b-1ec58b4d834d",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:42:46.470Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2d0238b2cb1227fcb4a3c",
        },
        name: "SUDHANSU  SAHOO",
        designation: "Front-End Web Application Development",
        id: "e1eb39be-91d0-45cf-9684-a7768e3a773d",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/e1eb39be-91d0-45cf-9684-a7768e3a773d",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Computer Science & Engineering",
        timestamp: {
            $date: "2024-09-24T14:43:47.114Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2d05d8b2cb1227fcb4a3f",
        },
        name: "PRITI BEHERA",
        designation: "Front-End Web Application Development",
        id: "fb9db0ec-7559-4d8b-a474-71cb10f89cd8",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/fb9db0ec-7559-4d8b-a474-71cb10f89cd8",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "Master  in Computer Application",
        timestamp: {
            $date: "2024-09-24T14:44:45.824Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "66f2d09b8b2cb1227fcb4a42",
        },
        name: "ALKA MAHARATHA",
        designation: "Front-End Web Application Development",
        id: "89ed1eb2-d242-4947-91c9-74718f6d04d2",
        issueDate: "2024-08-01",
        qrCodeValue:
            "https://www.squbix.com/certificate/89ed1eb2-d242-4947-91c9-74718f6d04d2",
        endDate: "2024-07-20",
        startDate: "2024-06-25",
        college: "Silicon Institute of Technology, Bhubaneswar",
        branch: "MTECH - CSE",
        timestamp: {
            $date: "2024-09-24T14:45:47.297Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "676d2f7ddf234112ef5c81cc",
        },
        name: "Dharmaraj Jena",
        designation: "Backend/Cloud Developer",
        id: "88ff9517-1d5e-4785-8016-35bf0edab01d",
        issueDate: "2024-12-26",
        qrCodeValue:
            "https://www.squbix.com/certificate/88ff9517-1d5e-4785-8016-35bf0edab01d",
        endDate: "2024-12-31",
        startDate: "2024-08-01",
        college: "Silicon Institute of Technology",
        branch: "Computer Engineering",
        timestamp: {
            $date: "2024-12-26T10:27:09.988Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "676d31dcdf234112ef5c81da",
        },
        name: "Vivek Ranjan Sahoo",
        designation: "Backend/Cloud Developer",
        id: "a621d804-d851-4516-ae23-3fd2ce87fd60",
        issueDate: "2024-12-26",
        qrCodeValue:
            "https://www.squbix.com/certificate/a621d804-d851-4516-ae23-3fd2ce87fd60",
        endDate: "2024-12-31",
        startDate: "2024-08-01",
        college: "Silicon Institute of Technology",
        branch: "Computer Science and Engineering",
        timestamp: {
            $date: "2024-12-26T10:37:16.205Z",
        },
        __v: 0,
    },
    {
        _id: {
            $oid: "a8438e6c5da24962a6418811f48e9eff",
        },
        name: "Sushree Dandia",
        designation: "SQL and JAVA SCRIPT",
        id: "a8438e6c-5da2-4962-a641-8811f48e9eff",
        issueDate: "2025-03-17",
        qrCodeValue:
            "https://www.squbix.com/certificate/a8438e6c-5da2-4962-a641-8811f48e9eff",
        endDate: "2025-03-13",
        startDate: "2024-12-12",
        college: "ITER",
        branch: "CSE",
        timestamp: {
            $date: "2025-03-17T00:00:00.000Z",
        },
        __v: 0,
    },
];

