import React, { useState, useEffect, useRef } from "react";
import "../Css/Service.css";
import CS from "../Assets/Images/Services/CS.png";
import PD from "../Assets/Images/Services/PD.png";
import TC from "../Assets/Images/Services/TC.png";
import RM from "../Assets/Images/Services/RM.png";
import { useInView } from "framer-motion";

export const Service = ({serviceRef}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const intervalRef = useRef();
  const headerRef = useRef();
  const isInView = useInView(headerRef, { once: true })

  const services = [
    {
      name: "Product Development",
      image: PD,
      description:
        "From concept to execution, we specialize in bringing your ideas to life through meticulous product development. Our team ensures that every aspect of your product is carefully crafted to meet your needs and exceed your expectations.",
      color: "rgba(189, 224, 254, 1)",
    },
    {
      name: "Custom Solutions",
      image: CS,
      description:
        "No two businesses are alike, which is why we offer customized solutions tailored specifically to your unique needs. Whether you require software development, system integration, or workflow automation, our experts are dedicated to delivering solutions that perfectly align with your objectives.",
      color: "rgba(248, 173, 134, 1)",
    },
    {
      name: "Technical Consulting",
      image: TC,
      description:
        "Leverage our expertise to navigate the complexities of technology and make the right decisions for your business. Our technical consulting services provide valuable insights and recommendations to help you optimize your system, increase productivity and stay ahead of the curve.",
      color: "rgba(243, 238, 144, 1)",
    },
    {
      name: "Rigorous Maintenance",
      image: RM,
      description:
        "Ensure your systems perform smoothly with our intensive maintenance services. We provide proactive monitoring, troubleshooting, and updates to keep your technology running smoothly and reduce downtime, so you can focus on your business.",
      color: "rgba(214, 184, 236, 1)",
    },
  ];

  useEffect(() => {
    if (!isPaused && isInView) {
      intervalRef.current = setInterval(() => {
        setActiveIndex((prevIndex) =>
          prevIndex === services.length - 1 ? 0 : prevIndex + 1
        );
      }, 10000);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isPaused, services.length, isInView]);

  return (
    <div className="homeservice-main" ref={serviceRef}>
      <div className="homeservice-heading" ref={headerRef}>What we do best</div>
      <div className="service-bg1"></div>
      <div className="main-service-slider">
        <div className="main-service-slider-nav">
          {services.map((service, index) => (
            <div
              key={index}
              className={`main-service-slider-nav-item ${
                activeIndex === index
                  ? isPaused
                    ? "slide-active paused"
                    : "slide-active"
                  : ""
              }`}
              onClick={() => {
                setActiveIndex(index);
                setIsPaused(true);
              }}
            >
              {service.name}
              {activeIndex === index && !isPaused && (
                <div className="progress-bar" />
              )}
            </div>
          ))}
        </div>

        <div className="mobile-chip-nav">
          {services.map((service, index) => (
            <div
              key={index}
              className={`mobile-chip-nav-item ${
                activeIndex === index
                  ? isPaused
                    ? "slide-active paused"
                    : "slide-active"
                  : ""
              }`}
              onClick={() => {
                setActiveIndex(index);
                setIsPaused(true);
              }}
              style={
                index === activeIndex ? {
                  backgroundColor: services[index].color,
                  color:"black"
                }: {
                  border: `1px solid ${services[index].color}`,
                  color: services[index].color
                }
              }
            >
              {service.name}
            </div>
          ))}
        </div>


        <div
          className="main-service-slider-content"
          style={{ backgroundColor: services[activeIndex].color }}
        >
          {services.map((service, index) => (
            <div
              key={index}
              className={`main-service-slider-content-item ${
                activeIndex === index ? "slide-active" : ""
              }`}
            >
              <div className="main-service-slider-content-item-image">
                <img src={service.image} alt={service.name} />
              </div>
              <div className="main-service-slider-content-item-text">
                {service.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
