import React from "react";
import "./Css/Footer.css";
import SquL from "./Assets/Images/Footer/Squ_logo_white.png";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram, FaMeta } from "react-icons/fa6";
// import isoMark from "../Footer/Assets/Images/Footer/isocertf.png";
import { TbMailFilled } from "react-icons/tb";

const Footer = () => {
  return (
    <footer
      className="footer"
      style={{
        zIndex: 10,
        userSelect: "auto",
        color: "rgba(255, 255, 255, 0.5)",
      }}
    >
      <div className="footer-top">
        <div className="footer-left">
          <div className="footer-squbix">
            <div className="footer-squbix-logo">
              <img src={SquL} alt="Squubix Logo" />
              <span className="footer-squbix-logo-text">Squbix Digital</span>
            </div>
          </div>
          <div className="footer-isoMark">
            <img
              src="https://ik.imagekit.io/dzzp3vpnx/Others/Frame%201597880657.png?updatedAt=1727156555703"
              alt="ISO Certified"
            />
            <img
              src="https://ik.imagekit.io/dzzp3vpnx/Others/logorev.jpg?updatedAt=1724232842403"
              alt="UASL Certified"
            />
            <img
              src="https://ik.imagekit.io/dzzp3vpnx/Others/QC.png?updatedAt=1724233458958updatedAt=1724233852559
              "
              alt="QCC Certified"
            />
            <img
              src="https://ik.imagekit.io/dzzp3vpnx/Others/msme-certificate-1000x1000.jpg?updatedAt=1724233852559
              "
              alt="MSME Certified"
            />
          </div>
        </div>

        <div className="footer-right">
          <div className="footer-quick-link">
            <div className="footer-heading">Quick Links</div>
            <div className="footer-link">
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/products">Product</Link>
              <Link to="/training">Training</Link>
              <Link to="/blog">Blog</Link>
            </div>
          </div>
          <div className="footer-contact">
            <div className="footer-heading">Connect with us</div>
            <div className="footer-link">
              <a href="mailto:contact@squbix.com">
                <TbMailFilled size={18} /> contact@squbix.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-copyright">
          <span>Copyright 2024</span>
          <span>|</span>
          <span>All Rights Reserved</span>
        </div>

        <div className="footer-social">
          <a
            href="https://www.linkedin.com/company/squbix/mycompany/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin size={28} />
          </a>
          <a
            href="https://www.instagram.com/squbixdigital/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram size={28} />
          </a>
          <a
            href="https://www.facebook.com/squbixdigital"
            target="_blank"
            rel="noreferrer"
          >
            <FaMeta size={28} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
