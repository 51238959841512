import React from "react";

const RefundPolicy = () => {
  return (
    <div style={{paddingTop: "60px", minHeight: "100vh"}}>
      <div className="container align-items-center text-center text-sm-start text-white">
        <div>
          <h2>Return and Refund Policy</h2>
          <p>
            Last updated: November 22, 2023
            <br /> 
            <br />
            Thank you for shopping at squbix digital website and Squbix app.
            <br />
            <br />
            If, for any reason, You are not completely satisfied with a purchase
            We invite You to review our policy on refunds and returns. This
            Return and Refund Policy has been created with the help of the Free
            Return and Refund Policy Generator.
            <br />
            <br />
            The following terms are applicable for any products that You
            purchased with Us.
          </p>
        </div>
        <div>
          <h2>Interpretation and Definitions</h2>
          <h4>Interpretation</h4>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h4>Definitions</h4>
          <p>
            For the purposes of this Return and Refund Policy:
            <ul>
              <li>
                <b>Application</b> means the software program provided by the
                Company downloaded by You on any electronic device, named
                Squbix.
              </li>
              <li>
                <b>Company</b> (referred to as either "the Company", "We", "Us"
                or "Our" in this Agreement) refers to Squbix Digital pvt ltd.,
                RN926,DLF cyber city,Patia,Bhubaneswar,751024.
              </li>
              <li>
                <b>Goods</b> refer to the items offered for sale on the Service.
              </li>
              <li>
                <b>Orders</b> mean a request by You to purchase Goods from Us.
              </li>
              <li>
                <b>Services</b> refers to the Application or the Website or
                both.
              </li>
              <li>
                <b>Website</b> refers to squbix digital, accessible from
                <a href="/"> www.squbix.com</a>
              </li>
              <li>
                <b>You</b> means the individual accessing or using the Service,
                or the company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </li>
            </ul>
          </p>
        </div>
        <div>
          <h2>Your Order Cancellation Rights</h2>
          <p>
            You are entitled to cancel Your Order within 14 days without giving
            any reason for doing so. The deadline for cancelling an Order is 14
            days from the date on which You received the Goods or on which a
            third party you have appointed, who is not the carrier, takes
            possession of the product delivered. In order to exercise Your right
            of cancellation, You must inform Us of your decision by means of a
            clear statement. You can inform us of your decision by:
            <ul>
              <li>By email: support@squbix.com</li>
              <li>
                By visiting this page on our website:{" "}
                <a href="/">www.squbix.com</a>
              </li>
            </ul>
            We will reimburse You no later than 14 days from the day on which We
            receive the returned Goods. We will use the same means of payment as
            You used for the Order, and You will not incur any fees for such
            reimbursement.
          </p>
        </div>

        <div>
          <h2>Conditions for Returns</h2>
          <p>
            In order for the Goods to be eligible for a return, please make sure
            that:
            <ul>
              <li>The Goods were purchased in the last 14 days</li>
              <li>The Goods are in the original packaging</li>
            </ul>
          </p>
          <p>
            The following Goods cannot be returned:
            <ul>
              <li>
                The supply of Goods made to Your specifications or clearly
                personalized.
              </li>
              <li>
                The supply of Goods which according to their nature are not
                suitable to be returned, deteriorate rapidly or where the date
                of expiry is over.
              </li>
              <li>
                The supply of Goods which are not suitable for return due to
                health protection or hygiene reasons and were unsealed after
                delivery.
              </li>
              <li>
                The supply of Goods which are, after delivery, according to
                their nature, inseparably mixed with other items.
              </li>
            </ul>
            We reserve the right to refuse returns of any merchandise that does
            not meet the above return conditions in our sole discretion.
            <br />
            <br />
            Only regular priced Goods may be refunded. Unfortunately, Goods on
            sale cannot be refunded. This exclusion may not apply to You if it
            is not permitted by applicable law.
          </p>
        </div>

        <div>
          <h2>Returning Goods</h2>
          <p>
            If the Goods were marked as a gift when purchased and then shipped
            directly to you, You'll receive a gift credit for the value of your
            return. Once the returned product is received, a gift certificate
            will be mailed to You.
            <br />
            <br />
            If the Goods weren't marked as a gift when purchased, or the gift
            giver had the Order shipped to themselves to give it to You later,
            We will send the refund to the gift giver.
          </p>
        </div>

        <div>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about our Returns and Refunds Policy,
            please contact us:
            <ul>
              <li>By email: support@squbix.com</li>
              <li>
                By visiting this page on our website:{" "}
                <a href="/">www.squbix.com</a>
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
