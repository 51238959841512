import React, { useState } from "react";
import "./Css/ServicePage.css";
//import TestAutomationImg from "../Assets/Images/ServicePage/TestAutomationImg.png";
import mob from './Assets/Gifs/ServicePage/mob.gif'
import ai from './Assets/Gifs/ServicePage/ai.gif'
import devop from './Assets/Gifs/ServicePage/devop.gif'
import ux from './Assets/Gifs/ServicePage/ux.gif'
import web from './Assets/Gifs/ServicePage/web.gif'
import blockchain from './Assets/Gifs/ServicePage/blockchain.gif'
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
const ServicePage = () => {
   const [activeSection, setActiveSection] = useState("testAutomation");



  return (
    <>
      <Navbar WhichTab={"services"} />
       <div className="services-style">
        <nav className="ServiceSelected">
          <button
            onClick={() => setActiveSection("testAutomation")}
            className={
              activeSection === "testAutomation"
                ? "custom-btn-selected"
                : "custom-btn"
            }
          >
            Testing & Automation
          </button>
          <button
            onClick={() => setActiveSection("blockchain")}
            className={
              activeSection === "blockchain"
                ? "custom-btn-selected"
                : "custom-btn"
            }
          >
            Blockchain
          </button>
          <button
            onClick={() => setActiveSection("webDev")}
            className={
              activeSection === "webDev"
                ? "custom-btn-selected"
                : "custom-btn"
            }
          >
            Web Development
          </button>
          <button
            onClick={() => setActiveSection("devOps")}
            className={
              activeSection === "devOps"
                ? "custom-btn-selected"
                : "custom-btn"
            }
          >
            DevOps
          </button>
          <button
            onClick={() => setActiveSection("ai")}
            className={
              activeSection === "ai"
                ? "custom-btn-selected"
                : "custom-btn"
            }
          >
            AI
          </button>
          <button
            onClick={() => setActiveSection("uxDesign")}
            className={
              activeSection === "uxDesign"
                ? "custom-btn-selected"
                : "custom-btn"
            }
          >
            UX Design
          </button>
          <button
            onClick={() => setActiveSection("mobileDev")}
            className={
              activeSection === "mobileDev"
                ? "custom-btn-selected"
                : "custom-btn"
            }
          >
            App Development
          </button>




        </nav>

        {activeSection === "testAutomation" && (
          <div className="services-section">
            <div className="services-section-l">
              <img src={ai} alt="" />
            </div>
            <div className="services-section-r">
              <div className="services-section-r-1">
                <p>Comprehensive Testing</p>
              </div>
              <div className="services-section-r-2">
                <p>
                  We perform comprehensive software & app testing using tools like{" "}
                  <span style={{ color: "#FFFF00" }}>
                    Selenium, Appium, Postman, JMeter
                  </span>{" "}
                  & many more to identify & resolve root-level bugs, ensuring
                  top-notch digital product quality for our clients.
                </p>
              </div>
              <div className="services-section-r-3">
                <a href="/testExpanded" style={{ color: "#a7c957" }}>
                  Read More
                </a>
              </div>
            </div>
          </div>
        )}

        {activeSection === "webDev" && (
          <div className="services-section">
            <div className="services-section-l">
              <img src={web} alt="" />
            </div>
            <div className="services-section-r">
              <div>
                <div className="services-section-r-1">
                  <p>Robust Solutions</p>
                </div>
                <div className="services-section-r-2">
                  <p>
                    Crafting robust web solutions with{" "}
                    <span style={{ color: "#FFFF00" }}>React</span> for front-end &{" "}
                    <span style={{ color: "#FFFF00" }}>Node.js</span> with{" "}
                    <span style={{ color: "#FFFF00" }}>NEST</span> for back-end
                    development to demonstrate our expertise & deliver high-quality
                    results to our clients.
                  </p>
                </div>
              </div>
              <div className="services-section-r-3">
                <a href="/testExpanded" style={{ color: "#a7c957" }}>
                  Read More
                </a>
              </div>
            </div>
          </div>
        )}
        {activeSection === "mobileDev" && (
          <div className="services-section">
            <div className="services-section-l">
              <img src={mob} alt="" />
            </div>
            <div className="services-section-r">
              <div>
                <div className="services-section-r-1">
                  <p>Top-Tier Apps</p>
                </div>
                <div className="services-section-r-2">
                  <p>
                    Building top-tier mobile apps using{" "}
                    <span style={{ color: "#FFFF00" }}>React Native</span> for
                    front-end and <span style={{ color: "#FFFF00" }}>Node.js</span>{" "}
                    for back-end development, showcasing our expert proficiency in
                    delivering cutting-edge mobile solutions to clients.
                  </p>
                </div>
              </div>
              <div className="services-section-r-3">
                <a href="/testExpanded" style={{ color: "#a7c957" }}>
                  Read More
                </a>
              </div>
            </div>
          </div>
        )}
        {activeSection === "uxDesign" && (
          <div className="services-section">
            <div className="services-section-l">
              <img src={ux} alt="" />
            </div>
            <div className="services-section-r">
              <div>
                <div className="services-section-r-1">
                  <p>Comprehensive Testing</p>
                </div>
                <div className="services-section-r-2">
                  <p>
                    Designing user-friendly digital products with{" "}
                    <span style={{ color: "#FFFF00" }}>Figma</span> , showcasing our
                    expertise and proficiency in UI/UX to create seamless and
                    intuitive user experiences.
                  </p>
                </div>
              </div>
              <div className="services-section-r-3">
                <a href="/testExpanded" style={{ color: "#a7c957" }}>
                  Read More
                </a>
              </div>
            </div>
          </div>
        )}
        {activeSection === "blockchain" && (
          <div className="services-section">
            <div className="services-section-l">
              <img src={blockchain} alt="" />
            </div>
            <div className="services-section-r">
              <div>
                <div className="services-section-r-1">
                  <p>User Friendly Products</p>
                </div>
                <div className="services-section-r-2">
                  <p>
                    Utilizing blockchain technology to enhance{" "}
                    <span style={{ color: "#FFFF00" }}>trust</span>,{" "}
                    <span style={{ color: "#FFFF00" }}>transparency</span> &{" "}
                    <span style={{ color: "#FFFF00" }}>decentralization</span> in
                    web experiences and development, demonstrating our expertise,
                    proficiency, and commitment to security.
                  </p>
                </div>
              </div>
              <div className="services-section-r-3">
                <a href="/testExpanded" style={{ color: "#a7c957" }}>
                  Read More
                </a>
              </div>
            </div>
          </div>
        )}
        {activeSection === "devOps" && (
          <div className="services-section">
            <div className="services-section-l">
              <img src={devop} alt="" />
            </div>
            <div className="services-section-r">
              <div>
                <div className="services-section-r-1">
                  <p>Scalable Products</p>
                </div>
                <div className="services-section-r-2">
                  <p>
                    Enhancing cloud services and product scalability through DevOps,
                    leveraging tools such as{" "}
                    <span style={{ color: "#FFFF00" }}>AWS</span>,{" "}
                    <span style={{ color: "#FFFF00" }}>Azure</span>,{" "}
                    <span style={{ color: "#FFFF00" }}>Docker</span>, and
                    <span style={{ color: "#FFFF00" }}> Kubernetes</span> , to
                    exemplify our expertise and proficiency.
                  </p>
                </div>
              </div>
              <div className="services-section-r-3">
                <a href="/testExpanded" style={{ color: "#a7c957" }}>
                  Read More
                </a>
              </div>
            </div>
          </div>
        )}
        {activeSection === "ai" && (
          <div className="services-section">
            <div className="services-section-l">
              <img src={ai} alt="" />
            </div>
            <div className="services-section-r">
              <div>
                <div className="services-section-r-1">
                  <p>Harnessing AI</p>
                </div>
                <div className="services-section-r-2">
                  <p>
                    “Utilizing AI technology, including tools like{" "}
                    <span style={{ color: "#FFFF00" }}>ChatGPT</span> and{" "}
                    <span style={{ color: "#FFFF00" }}>MidJourney</span>, to
                    efficiently develop and design cutting-edge digital products,
                    showcasing our expertise and proficiency."
                  </p>
                </div>
              </div>
              <div className="services-section-r-3">
                <a href="/testExpanded" style={{ color: "#a7c957" }}>
                  Read More
                </a>
              </div>
            </div>
          </div>
        )}
      </div>      
       <Footer />
    </>
  );
};

export default ServicePage;




// import React, { useRef } from "react";
// import Navbar from "../Navbar/Navbar";
// import service_card from "./Assets/Gifs/ServicePage/Home.png";
// import './Css/ServicePage.css'
// import Products from "../HomePage/Components/Products";
// import Dashboard from "../HomePage/Components/Dashboard";
// import Teams from "../HomePage/Components/Teams";
// import '../HomePage/Css/HomePage.css';
// const ServicePage = () => {
//   const dashboardRef = useRef(null);
//   const teamRef = useRef(null);
//   return (
//     <div className='service_container'>
//       <div id="stars"></div>
//       <div id="stars2"></div>
//       <div id="stars3"></div>
//       <Navbar WhichTab={"services"} />
//       <img src={service_card} href="service_card " style={{ height: "80vh", width: "100%", padding: " 0% 2%" }} />
//       <div className='service_button' style={{ display: "flex", justifyContent: "center", margin: "3% 22%", gap: "5rem", flexWrap: "wrap" }}>
//         <button id='button_id' style={{ padding: "2%", width: "9rem", backgroundColor: "rgba(255,255,255,0.2)", border: " 0.33px solid rgba(126, 126, 126, 1)", color: "white" }}>React js</button>
//         <button id='button_id' style={{ padding: "2%", width: "9rem", backgroundColor: "rgba(255,255,255,0.2)", border: " 0.33px solid rgba(126, 126, 126, 1)", color: "white" }}>Node.js</button>
//         <button id='button_id' style={{ padding: "2%", width: "9rem", backgroundColor: "rgba(255,255,255,0.2)", border: " 0.33px solid rgba(126, 126, 126, 1)", color: "white" }}>AWS</button>
//         <button id='button_id' style={{ padding: "2%", width: "9rem", backgroundColor: "rgba(255,255,255,0.2)", border: " 0.33px solid rgba(126, 126, 126, 1)", color: "white" }}>Azure</button>
//         <button id='button_id' style={{ padding: "2%", width: "9rem", backgroundColor: "rgba(255,255,255,0.2)", border: " 0.33px solid rgba(126, 126, 126, 1)", color: "white" }}>Azure</button>
//         <button id='button_id' style={{ padding: "2%", width: "9rem", backgroundColor: "rgba(255,255,255,0.2)", border: " 0.33px solid rgba(126, 126, 126, 1)", color: "white" }}>Python</button>
//         <button id='button_id' style={{ padding: "2%", width: "9rem", backgroundColor: "rgba(255,255,255,0.2)", border: " 0.33px solid rgba(126, 126, 126, 1)", color: "white" }}>Ethereum</button>
//         <button id='button_id' style={{ padding: "2%", width: "9rem", backgroundColor: "rgba(255,255,255,0.2)", border: " 0.33px solid rgba(126, 126, 126, 1)", color: "white" }}>Rust</button>
//       </div>
//       <div className="prodHeading">Our Tech Stack</div>
//       <div className="offers">
//         <p className="offersHeading">What we <span>offers</span></p>
//         <p className="offersDetails">Your one-stop solution for business success in today's dynamic market. Our Services range from idea transformation to product enhancement:
//         </p>
//       </div>

//       <div className="prodHeading"> Crafted with{" "}<span style={{ color: "#DBFF00", opacity: "100%" }}>Love</span>
//       </div>
//       <Products/>
//       <div className="scroll-area stat-area">
//         <Dashboard dashboardRef={dashboardRef} />
//       </div>
//       <div className="scroll-area profile-area" ref={teamRef}>
//         <Teams />
//       </div>
//     </div>

//   )
// }

// export default ServicePage;