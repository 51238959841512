import React from "react";
import Marquee from "react-marquee-slider";
import styled from "styled-components";
import "../Css/Language.css";

import aws from "../Assets/IMG/Languages/AWS.png";
import Docker from "../Assets/IMG/Languages/Docker.png";
import firebase from "../Assets/IMG/Languages/Firebase.png";
import next from "../Assets/IMG/Languages/Nextjs.png";
import react from "../Assets/IMG/Languages/React.png";
import rust from "../Assets/IMG/Languages/Rust.png";
import ethereum from "../Assets/IMG/Languages/ethereum.png";
import kafka from "../Assets/IMG/Languages/kafka.png";
import node from "../Assets/IMG/Languages/node.png";
import graphql from "../Assets/IMG/Languages/graphql.png";
import kubernetes from "../Assets/IMG/Languages/kubernetes.png";
import mongodb from "../Assets/IMG/Languages/mongodb.png";
import reactnative from "../Assets/IMG/Languages/reactnative.png";

const languageLogos = [
  { id: "1", img: aws },
  { id: "2", img: Docker },
  { id: "3", img: firebase },
  { id: "4", img: next },
  { id: "5", img: react },
  { id: "6", img: ethereum },
  { id: "7", img: kafka },
  { id: "8", img: node },
  { id: "9", img: rust },
  { id: "10", img: graphql },
  { id: "11", img: kubernetes },
  { id: "12", img: mongodb },
  { id: "13", img: reactnative },
];

// Styled component for individual logo
const LogoWrapper = styled.div`
  width: 200px;
  margin: 0 20px;
`;

const Language = () => {
  return (
    <div className="carousel-pages">
      <Marquee velocity={50} direction="ltr" scatterRandomly={false} resetAfterTries={200}>
        {languageLogos.map((image, index) => (
          <LogoWrapper key={index}>
            <img src={image.img} alt={`language-${index}`} loading="lazy" style={{ width: "100%", height: "auto" }} />
          </LogoWrapper>
        ))}
      </Marquee>
    </div>
  );
};

export default Language;
