import React, { useEffect, useRef, useState } from "react";
import "../Css/HeroSection.css";
import Aos from "aos";
import { motion, AnimatePresence } from "framer-motion";
import "aos/dist/aos.css";
// import singapore from "../Assets/Images/Location/Singapore.png";
// import singapore from "../Assets/Images/Location/Singapore.jpeg";
// import dubai from "../Assets/Images/Location/Dubai.png";
// import bhubaneswar from "../Assets/Images/Location/Bhubaneswar.svg";
// import sydney from "../Assets/Images/Location/Sydney.png";
import down from "../Assets/Images/HomePage/Down.svg";
import { MdLocationPin } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { useSwipeable } from "react-swipeable";

const HeroSection = ({ heroRef, serviceRef }) => {
  //* Details of every located Company Details
  //* ------------------------------------------------

  const location = [
    {
      name: "Singapore",
      img: "https://ik.imagekit.io/dzzp3vpnx/SqubixHomepageImage/Singapore.jpeg?updatedAt=1723632962228",
      regd: "Singapore UEN - 202101854M",
      address: "11 Collyer Quay. #11-06 The Arcade,",
      mainAddress: "Singapore - 049317",
      email: "singapore@squbix.com",
      stateName: "Singapore",
      motto_sub:
        "Adaptable Solutions: Cloud Agnostic with On-Premises Capability",
      motto: "Build Your SaaS-Based Multi-Tenant Platform",
    },
    {
      name: "Dubai",
      img: "https://ik.imagekit.io/dzzp3vpnx/SqubixHomepageImage/Dubai.png?updatedAt=1722934034662",
      regd: "Dubai - Regd No 2168676",
      address: "SQUBIX DIGITAL L.L.C, THE BINARY BY OMNIYAT, Plot Number 177-0",
      mainAddress: " Business Bay, Dubai",
      email: "uae@squbix.com",
      stateName: "UAE",
      motto_sub: "Rapid Development at an Affordable Cost",
      motto: "Build Your Own Configurable & Customizable ERP System",
    },
    {
      name: "Bhubaneswar",
      img: "https://ik.imagekit.io/dzzp3vpnx/SqubixHomepageImage/BB.jpeg?updatedAt=1725267241664",
      regd: "India CIN - U72200OR2016PTC019786",
      address: "DCB 926, DLF Cyber City, Infocity, Patia,",
      mainAddress: "Bhubaneswar, India - 751024",
      email: "info@squbix.com",
      stateName: "India",
      motto_sub: "Fast-Track Your Journey from Idea to Play Store",
      motto: "Build Your Mobile App, Delivered Fast and Efficiently",
    },
    {
      name: "Sydney",
      img: "https://ik.imagekit.io/dzzp3vpnx/SqubixHomepageImage/Sydney.jpg?updatedAt=1723095217564",
      regd: "Australia Company No - 667 522 401",
      address: "U2 23 Montrose Street, Quakers Hill, NSW 2763,",
      mainAddress: "Sydney, Australia",
      email: "austarlia@squbix.com",
      stateName: "Australia",
      motto_sub: "Expertly Shaping Your AI Vision into Tangible Results",
      motto: "Elevate Your AI Innovations with Our Expert Solution Lab",
    },
    {
      name: "USA",
      img: "https://ik.imagekit.io/dzzp3vpnx/SqubixHomepageImage/usa2.jpg?updatedAt=1725252251811",
      regd: "",
      address: "2 Budd Cir, Shelton, CT,06484",
      mainAddress: "",
      email: "usa@squbix.com",
      stateName: "USA",
      motto_sub:
        "Highly Flexible and Configurable Products with Cost-Effective Management",
      motto: "Build Your Innovations by Value-Driven Solutions",
    },
  ];

  //* Manage the states of the HeroSection Component
  //* ------------------------------------------------

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [key, setKey] = useState(0); // New state variable
  const [location_view, setLocation_view] = useState(false);
  const [mobile_view_card, setMobile_view_card] = useState(true);
  const cardRef = useRef(null);

  useEffect(() => {
    Aos.init();
  }, []);

  //* Manage the image sliding effect of dots
  //* ------------------------------------------

  const goToImage = (index) => {
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % location.length);
      setKey((prevKey) => prevKey + 1); // Update the key every time the interval runs
    }, 10000);
    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [goToImage]);

  //* Manage the location view of the image by clicking or hover on location
  //* -----------------------------------------------------------------------

  const location_preview = () => {
    setLocation_view(true);
  };
  const mobile_card_view = () => {
    setMobile_view_card(!mobile_view_card);
  };

  //* Card View blocked when clicked outside the card container
  //* ----------------------------------------------------------
  useEffect(() => {
    if (location_view) {
      const handleClickOutside = (event) => {
        if (cardRef.current && !cardRef.current.contains(event.target)) {
          setLocation_view(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [location_view]);

  const handleSwipeLeft = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === location.length - 1 ? 0 : prevIndex + 1
    );
  };
  const handleSwipeRight = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? location.length - 1 : prevIndex - 1
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    trackMouse: true, // Enables mouse swiping
  });

  //* Highlighted Texts
  //* -------------------

  const highlightText = (text) => {
    return text.replace(
      /(SaaS|Multi-Tenant|ERP|Build|Configurable |Customizable|Mobile App|Fast|Efficiently|Innovations|Expert Solution|Elevate)/g,
      '<span class="green-text">$1</span>'
    );
  };
  //* Return the UI of HeroSection Component
  //* ---------------------------------------

  return (
    <div className="Hero" key={currentImageIndex} {...handlers}>
      <AnimatePresence>
        <motion.img
          key={`image-${currentImageIndex}`}
          initial={{ opacity: 1, scale: 1 }}
          animate={{ opacity: 1, scale: 1.09 }}
          exit={{ opacity: 1, scale: 1 }}
          transition={{ duration: 8, ease: "easeInOut" }}
          class="img-fluid"
          src={location[currentImageIndex].img}
          alt={location[currentImageIndex].name}
          className="location_image"
        />
      </AnimatePresence>
      <div className="type-main" ref={heroRef}>
        <div data-aos="fade-up" className="motto_text">
          <h3
            key={`motto-${currentImageIndex}`}
            dangerouslySetInnerHTML={{
              __html: highlightText(location[currentImageIndex].motto),
            }}
          ></h3>
          <h5 key={`motto-sub${currentImageIndex}`}>
            {location[currentImageIndex].motto_sub}
          </h5>
        </div>
        <div className="section_dots">
          {location.map((_, index) => (
            <GoDotFill
              key={`dot-${index}`}
              className={`dot${
                index === currentImageIndex ? "active" : "noactive"
              }`}
              onClick={() => goToImage(index)}
            />
          ))}
        </div>
        <button
          class="round-button"
          className="location_btn"
          key={`button-${currentImageIndex}`}
          onMouseEnter={location_preview}
        >
          <MdLocationPin
            className={`loc_btn${location_view === true ? "active" : ""}`}
          />
          {!location_view && (
            <span className="location_name">
              {location[currentImageIndex].name}
            </span>
          )}
        </button>
        <AnimatePresence>
          {location_view && (
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 100 }}
              transition={{ duration: 0.6 }}
              ref={cardRef}
              class="card"
              onMouseLeave={() => setLocation_view(false)}
              className="loc_glassmorphism"
            >
              <div class="card-body" className="card_body">
                <h5 class="card-title" className="location-name">
                  {location[currentImageIndex].name}
                </h5>

                <div className="hero-location">
                  {location[currentImageIndex].address}
                </div>
                <div className="hero-location">
                  {location[currentImageIndex].mainAddress},&nbsp;
                  {location[currentImageIndex].email}
                </div>
                <div className="hero-location">
                  {location[currentImageIndex].regd}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        {/*Only For  Mobile Card View*/}
        <div className="tint_overlay_mobile"></div>
        <button
          class="round-button"
          key={`card-${currentImageIndex}`}
          className="location_mobile_btn"
          onClick={mobile_card_view}
        >
          <MdLocationPin
            className={`loc_mobile_btn${
              mobile_view_card === true ? "" : "block"
            }`}
          />
        </button>
        {mobile_view_card && (
          <>
            <div
              data-aos="fade-up"
              class="card"
              className="loc_glassmorphism_mobile"
              key={currentImageIndex}
              {...handlers}
            >
              <div class="card-body" className="card_body">
                <h5 class="card-title" className="location-name">
                  {location[currentImageIndex].name}
                </h5>

                <div className="hero-location">
                  {location[currentImageIndex].address}
                </div>
                <div className="hero-location">
                  {location[currentImageIndex].mainAddress &&
                    location[currentImageIndex].mainAddress}
                  ,&nbsp;
                  {location[currentImageIndex].email}
                </div>
                {/* Conditionally render the registration details */}
                {location[currentImageIndex].regd && (
                  <div className="hero-location">
                    {location[currentImageIndex].regd}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div className="backgroundTint"></div>

        <div
          className="scroll-animation"
          onClick={() =>
            serviceRef.current.scrollIntoView({ behavior: "smooth" })
          }
        >
          <img className="scroll_icon" src={down} alt="scroll down" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
