import { React, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { blogjson } from "../Assets/Data/blogsJSON.jsx";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import { AiFillFacebook } from "react-icons/ai";
import Footer from "../../Footer/Footer.js";
import { LinkedinShareButton, FacebookShareButton, TwitterShareButton } from "react-share";

import "../CSS/Detailspage.css";
const DetailsBlogPage = ({ setNav }) => {
    const { blogid } = useParams();
    const Bdata = blogjson[blogid];


    useEffect(() => {
        setNav("blog");
        // eslint-disable-next-line
    }, [])
    return (
        <div>
            <div className="details-wrapper">
                <div className="Card-name">
                    {Bdata?.name}
                </div>
                <div className="Date-icon-container">
                    <div style={{ padding: "10px 0px" }}>
                        <p className="Author">{Bdata?.author}</p>
                        <div className="dateTime-container">
                            <p className="Date">{Bdata?.date}</p>
                            <p className="readTime" style={{marginLeft:"6px"}}>• {Bdata?.readTime}</p>
                        </div>
                    </div>
                    <div className="icon-container">
                        <TwitterShareButton
                            url="https://squbix.com/"
                            windowPosition="center"
                            >
                            <FaXTwitter style={{ marginRight: "20px",cursor:"pointer" }} size={20} />
                        </TwitterShareButton>

                        <LinkedinShareButton
                            summary="Squbix's strategic location in Bhubaneswar's tech hub provides access to top organizations."
                            title="Share Squbix website"
                            source="LinkedIn"
                            url="https://squbix.com/blogs"
                            windowPosition="absolute"
                            >
                            <FaLinkedin style={{ marginRight: "20px",cursor:"pointer" }} size={20} />
                        </LinkedinShareButton>

                        <FacebookShareButton
                            url="https://squbix.com/blogs"
                            hashtags="#Squbix Digital"
                            quotes="'We have what we need, if we use what we have....'"
                            windowPosition=""
                        >
                            <AiFillFacebook style={{ cursor:"pointer" }} size={20} />
                        </FacebookShareButton>

                    </div>
                </div>
                <div className="about">{Bdata?.about}</div>
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <img src={Bdata.img} alt="cardImg" className="font-Img" />
                </div>
                {Bdata?.contents?.map((items, index) => {
                    return (
                        <div className="Blog-contents" key={index}>
                            {items?.Heading && (
                                <div className="content-heading">
                                    {items.Heading}
                                </div>
                            )}
                            <div className="">
                                {items?.Heading && (
                                    <div className="blog-content">
                                        {items.content}
                                        <pre style={items.imgText ? { background: "rgba(255,255,255,0.05)", margin: "5% 0", border: "1px solid rgba(255,255,255,0.5)", borderRadius: "10px" } : { background: "rgba(255,255,255,0.1)", border: "1 solid rgba(255,255,255,1)" }}>
                                            <code style={{ color: "rgba(255,255,255,0.75)", fontFamily: "Consolas" }}>{items?.imgText}</code></pre>
                                        <div className="blog-content2">
                                            {items?.content2}
                                        </div>
                                    </div>
                                )}

                                {items.subtitle &&
                                    <div>
                                        {items.subtitle.map((sub) => {
                                            return (
                                                <div className="sub-heading">
                                                    <div style={{}}> {sub.Heading}</div>
                                                    <div className="subtitle" style={{ fontWeight: "100" }}>{sub.content}</div>
                                                    <pre style={items.imgText ? { background: "rgba(255,255,255,0.1)", margin: "5% 0", fontFamily: "Consolas" } : { background: "rgba(255,255,255,0.1)", borderRadius: "10px", border: "1 solid rgba(255,255,255,1)" }}>
                                                        <code className="" style={{ fontWeight: "100", color: "rgba(255,255,255,0.75)" }}>{sub.imgText}</code>
                                                    </pre>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        </div>
                    );
                }
                )}
                <div className="blog-Conclusion">{Bdata.Conclusion}</div>
                <nav className="BlogButton" style={{ padding: "4rem 0rem", gap: "1rem" }}>
                    <button
                        className="blog-button">Blockchain</button>
                    <button
                        className="blog-button">Web Devlopment</button>
                    <button
                        className="blog-button">Cloud computing</button>
                    <button
                        className="blog-button" >AI</button>
                    <button
                        className="blog-button">ML</button>
                </nav>


            </div >
            <Footer />
        </div>
    )
}

export default DetailsBlogPage;